<template>
<!-- main -->
<main id="container">
    
    <div class="complete_logo_wrap">
		<h1><img class="symbol" src="/img/common/logo/peoplelife.png" alt="피플라이프"></h1>
	</div>
	<div class="complete__contents">
		<img src="/img/recruit/complete/cmm-recruit-complete.png" alt="">
		<h2>당신의 꿈, 그 실현을 위해 피플라이프가 함께 하겠습니다.</h2>
		<p>입사지원 신청이 완료되었습니다.<br>인사담당자가 확인 후 기재해주신 연락처로 전화드리겠습니다.<br>감사합니다.</p>
		<a href="javascript:history.back()" class="btn btn-line btn-ani btn-line--padding_edit"><span class="txt">이전화면으로 이동<span class="sp-img more-k"></span></span></a>
	</div>
</main>
<!-- /main -->
</template>

<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitcomplete.scss';
</style>

<script>
    export default {}
</script>