<template>
	<main id="container">
		<section id="map_wrap" class="section">
			<aside class="aside">
				<h3>사업단 검색</h3>
				<div class="channel_wrap">
					<button type="button" class="all active" value="" data-name="전체">전체</button>
					<button type="button" class="indi" value="2" data-name="개인">개인사업단</button>
					<button type="button" class="corp" value="1" data-name="법인">법인사업단</button>
					<button type="button" class="clinic" value="4" data-name="보험클리닉">보험클리닉</button>
					<button type="button" class="tm" value="5" data-name="TFA">TFA센터</button>
				</div>
				<div class="search_wrap">
					<div class="tab">
						<button type="button" class="tab_total active">통합검색</button>
						<button type="button" class="tab_region">지역검색</button>
					</div>
					<div class="contents">
						<ul>
							<li class="contents_total">
								<div class="input_wrap">
									<input type="text" class="total" placeholder="사업단명을 검색하세요">
								</div>
							</li>
							<li class="contents_region hidden">
								<div class="select_wrap">
									<select class="region1">
										<option value="" data-value="code" data-text="shortName">시/도</option>
									</select>
								</div>
								<div class="select_wrap">
									<select class="region2">
										<option value="" data-value="name" data-text="name">시/군/구</option>
									</select>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="count_wrap">
					<p><em class="region1"></em> <em class="region2"></em> 검색결과 <i class="count">0</i>건</p>
				</div>
				<div class="list_wrap">
					<ul class="list_contents swiper-wrapper">
						<li data-attr-id="deptId" data-attr-gubun="publicGubun" class="swiper-slide">
							<div class="info">
								<div class="tit">
									<strong class="shop_name" data-text="publicName"></strong>
									<p class="type">
										<i></i>
										<img src="/img/intro/branch/marker.svg" alt="">
									</p>
								</div>
								<address>
									<span class="address1"></span>
									<span class="address2"></span>
								</address>
								<span data-text="publicPhone" class="shop_phone"></span>
								<a href="javascript:;" target="_blank" class="find_shop">길찾기</a>
							</div>
						</li>
					</ul>
					<div class="swiper-scrollbar"></div>
				</div>
			</aside>
			<div class="map_wrap">
				<div class="map">
					<div class="shop_info">
						<div class="content">
							<div class="tit">
								<strong class="shop_name"></strong>
								<p class="type">
									<img src="/img/intro/branch/ico.svg" alt="">
									<i></i>
								</p>
							</div>
							<address>
								<span class="address1"></span>
								<span class="address2"></span>
							</address>
							<span class="shop_phone"></span>
							<a href="javascript:;" target="_blank" class="find_shop">길찾기</a>
						</div>
						<button type="button" class="close">닫기</button>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<style scoped lang="scss">
@import '@/assets/scss/intro/branch.scss';
</style>
<script>
export default {
	
}
</script>