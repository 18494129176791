<template>
    <main id="container">
        <section id="visual">
			<h3>피플라이프의 투명한 경영정보</h3>
		</section>

        <section id="notify_list">
            <div class="inner">
                <div class="tit_box">
                    <h3 class="title">경영공시</h3>
                    <p class="desc_section">피플라이프 경영의 투명성을 확대하기 위해 내용을 공시하고 있습니다.</p>
                </div>
                <div class="board_list">
                    <div class="board_menu">
                        <span>No.</span>
                        <span>제목</span>
                        <span>등록자</span>
                        <span>등록일</span>
                    </div>
                    <div class="list">
                        <ul>
                            <li class="item">
                                <a href="javascript:void(0)" class="item_inner" target="_blank">
                                    <i data-text="number">2</i>
                                    <em data-text="title">연결감사보고서 (2021.12)</em>
                                    <p data-text="create">이촌회계법인</p>
                                    <span data-text="date">2022-03-29 <i></i></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="pagination" aria-label="pagination">
                        <button type="button" class="prev first-prev"><i></i><i></i></button>
                        <button type="button" class="prev"><i></i></button>
                        <ul class="page">
                            <li><a href="javascript:void(0)" data-page="0">1</a></li>
                        </ul>
                        <button type="button" class="next"><i></i></button>
                        <button type="button" class="next last-next"><i></i><i></i></button>
                    </div>
                </div>

                <div class="button_wrap">
                    <p>더 많은 공시정보는 <span>전자공시 시스템(DART)</span>을 통해 확인 할 수 있습니다.</p>
                    <a href="https://dart.fss.or.kr/" target="_blank">Dart 바로가기</a>
                </div>
			</div>
        </section>
    </main>
</template>

<style scoped lang="scss">
    @import '@/assets/scss/intro/notify.scss';
</style>



<script>
export default {     
    // mounted() {
    //   let recaptchaScript = document.createElement('script')
    //   recaptchaScript.setAttribute('src', '/js/intro/notify.js')
    //   document.head.appendChild(recaptchaScript)
    // },
}
</script>