<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">가치를 공유하는 기업,<strong>피플라이프</strong></h3>
            <p class="desc_section">피플라이프는 더불어 다 같이 잘 살고,<br> 행복하게 만드는 공유 지향적 기업을 목표로 나아가겠습니다.<br> 그로 인해 받게 된 수많은 수상들도 사회공헌 활동을 통해<br>고객분들에게 보답하겠습니다. </p>
            <div class="award"></div>
        </div>
        <div class="light"></div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 컨설팅지원 -->
    <section id="support" class="section">
        <div class="inner">
            <ol class="list_support">
                <li class="item_support">
                    <div class="info">
                        <p class="tit">사회공헌활동</p>
                        <p class="desc">피플라이프는 사회적 기업으로서의 책임을 다하기 위해 지역에 도움이 필요한 취약계층을<br> 지원하는 활동을 비롯하여 사회적취약계층(저소득층, 미혼모, 장애인 등)의 자립지원을<br> 위해 2017년부터 매해 임직원의 자발적 모금활동 등 꾸준한 사회공헌 활동을 통해<br>나눔경영 실천에 앞장서고 있습니다.</p>
                    </div>
                    <div class="thumb">
                        <img src="/img/intro/social/support_thumb1.jpg" alt="사진">
                    </div>
                    <img class="bg_txt" src="/img/intro/social/support_bg_txt.png">
                </li>
                <li class="item_support">
                    <div class="info">
                        <p class="tit">업무제휴협약</p>
                        <p class="desc">피플라이프는 제휴 업체와 동반성장할 수 있는 건전한 기업 환경을 통해 제휴한<br> 모든 업체와 함께 성장하는 행동한 동행을 추구합니다. <br> 제휴사와 신뢰를 바탕으로 더불어 다 같이 잘살 수 있는<br> 동반성장을 모토로 공유 지향적 기업을 목표를 이루기 위해 항상 노력하고 있습니다.</p>
                    </div>
                    <img class="thumb" src="/img/intro/social/support_thumb2.jpg" alt="사진">
                </li>
                <li class="item_support">
                    <div class="info" >
                        <p class="tit">고객최우선 경영</p>
                        <p class="desc">피플라이프는 ‘고객에게 이로움을 추구함으로써, 기업도 더불어 성장할 수 있다’라는<br> 마음가짐, 즉 ‘남을 이롭게 함으로써 나를 이롭게 한다’는 이념 하에 고객을 최우선으로<br> 하는 고객최우선 경영을 실천하고 있습니다.  고객의 이로움을 함께 추구하는 활동의<br> 영향으로 ‘브랜드평판’ ‘고객이 신뢰하는 브랜드 수상’ ‘소비자만족지수’ 등의 다양한<br> 부문에서 수상하였습니다.</p>
                    </div>
                    <img class="thumb" src="/img/intro/social/support_thumb3.jpg" alt="사진">
                </li>
            </ol>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //컨설팅지원 -->

    <!-- history -->
    <section id="history" class="section">
        <div class="inner">
            <h3 class="tit_section">사회공헌 HISTORY</h3>
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" data-index="31">
                        <p class="tit">삼정KPMG 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="30">
                        <p class="tit">프리미엄 브랜드 대상 수상 (주최 : 주간동아)</p>
                        <img class="medal" src="/img/intro/social/history_tit_medal_2024.png">
                    </div>
                    <div class="swiper-slide" data-index="29">
                        <p class="tit">이브이케어와 전략적 업무협력협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="28">
                        <p class="tit">KBS 2021년 피플라이프 불우이웃돕기 성금</p>
                    </div>
                    <div class="swiper-slide" data-index="27">
                        <p class="tit">옵티메드(병원경영컨설팅) 업무협력협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="26">
                        <p class="tit">한국 M&amp;A거래소와 협력 MOU체결</p>
                    </div>
                    <div class="swiper-slide" data-index="25">
                        <p class="tit">보맵과 업무협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="24">
                        <p class="tit">헬스맥스와 전략적 업무협력협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="23">
                        <p class="tit">소비자 보호'완전판매' 캠페인 개최</p>
                    </div>
                    <div class="swiper-slide" data-index="22">
                        <p class="tit">탈 플라스틱 '고고 챌린지' 동참</p>
                    </div>

                    <div class="swiper-slide" data-index="21">
                        <p class="tit">희망2021 나눔캠페인 성금 기부 참여(KBS 보도)</p>
                    </div>
                    <div class="swiper-slide" data-index="20">
                        <p class="tit">코리아런드리와 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="19">
                        <p class="tit">롯데마트와 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="18">
                        <p class="tit">고객이 신뢰하는 브랜드 대상 수상 (주최 : 한경비즈니스)</p>
                        <img class="medal" src="/img/intro/social/history_tit_medal_tba.png">
                    </div>
                    <div class="swiper-slide" data-index="17">
                        <p class="tit">한국소비자만족지수 1위 수상 (주최 : 한경비즈니스)</p>
                        <img class="medal" src="/img/intro/social/history_tit_medal_ksci.png">
                    </div>
                    <div class="swiper-slide" data-index="16">
                        <p class="tit">KBS 불우이웃돕기 성금 방송보도</p>
                    </div>
                    <div class="swiper-slide" data-index="15">
                        <p class="tit">인바이유와 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="14">
                        <p class="tit">현대아이씨티, 나이스디앤비와 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="13">
                        <p class="tit">두물머리투자자문과 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="12">
                        <p class="tit">대한민국고객만족브랜드대상 수상 (주최 : 주간동아)</p>
                        <img class="medal" src="/img/intro/social/history_tit_medal_kcsb.png">
                    </div>
                    <div class="swiper-slide" data-index="11">
                        <p class="tit">한국고객만족도 1위 수상 (주최 : 한경비즈니스)</p>
                        <img class="medal" src="/img/intro/social/history_tit_medal_kcsi.png">
                    </div>
                    <div class="swiper-slide" data-index="10">
                        <p class="tit">사내 이웃돕기 모금운동 (KBS 보도)</p>
                    </div>
                    <div class="swiper-slide" data-index="9">
                        <p class="tit">(계속) 연말 KBS ‘희망나눔’ 이웃돕기 전 직원 성금 기탁</p>
                    </div>
                    <div class="swiper-slide" data-index="8">
                        <p class="tit">랭키닷컴 재무설계/컨설팅 분야 1위 선정 (주최 : 랭키닷컴)</p>
                    </div>
                    <div class="swiper-slide" data-index="7">
                        <p class="tit">‘찾아가는 경영컨설팅’ 중소·중견기업 리스크관리 세미나 실시</p>
                    </div>

                    <div class="swiper-slide" data-index="6">
                        <p class="tit">이노비즈협회와 업무제휴협약 체결</p>
                    </div>
                    <div class="swiper-slide" data-index="5">
                        <p class="tit">중소기업 경영자 대상 CEO골프클래식 개최 및 참가비 기부</p>
                    </div>
                    <div class="swiper-slide" data-index="4">
                        <p class="tit">세월호 유가족 대상 전 직원 성금 기탁</p>
                    </div>
                    <div class="swiper-slide" data-index="3">
                        <p class="tit">강화군 불우이웃돕기 및 사회복지시설 지원 성금 기탁</p>
                    </div>
                    <div class="swiper-slide" data-index="2">
                        <p class="tit">서울사회복지공동나눔회를 통해 ‘저소득 가정지원’ 매월 실시</p>
                    </div>
                    
                    <div class="swiper-slide" data-index="1">
                        <p class="tit">동작복지재단을 통한 ‘한부모 가정지원’</p>
                    </div>
                    <div class="swiper-slide" data-index="0">
                        <p class="tit">KBS, MBC ‘사랑의 열매’ 성금 기탁</p>
                    </div>
                </div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" data-index="31" style="background-image:url(/img/intro/social/history_thumb31.jpg)" title="삼정KPMG 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="30" style="background-image:url(/img/intro/social/history_thumb30.jpg)" title="프리미엄 브랜드 대상 수상 (주최 : 주간동아)"></div>
                    <div class="swiper-slide" data-index="29" style="background-image:url(/img/intro/social/history_thumb29.jpg)" title="이브이케어와 전략적 업무협력협약 체결"></div>
                    <div class="swiper-slide" data-index="28" style="background-image:url(/img/intro/social/history_thumb23.jpg)" title="KBS 2021년 피플라이프 불우이웃돕기 성금"></div>
                    <div class="swiper-slide" data-index="27" style="background-image:url(/img/intro/social/history_thumb22.jpg)" title="옵티메드(병원경영컨설팅) 업무협력협약 체결"></div>
                    <div class="swiper-slide" data-index="26" style="background-image:url(/img/intro/social/history_thumb21.jpg)" title="한국 M&A거래소와 협력 MOU체결"></div>
                    <div class="swiper-slide" data-index="25" style="background-image:url(/img/intro/social/history_thumb20.jpg)" title="보맵과 업무협약 체결"></div>
                    <div class="swiper-slide" data-index="24" style="background-image:url(/img/intro/social/history_thumb19.jpg)" title="헬스맥스와 전략적 업무협력협약 체결"></div>
                    <div class="swiper-slide" data-index="23" style="background-image:url(/img/intro/social/history_thumb18.jpg)" title="소비자 보호'완전판매' 캠페인 개최"></div>
                    <div class="swiper-slide" data-index="22" style="background-image:url(/img/intro/social/history_thumb0.jpg)" title="탈 플라스틱 '고고 챌린지' 동참"></div>

                    <div class="swiper-slide" data-index="21" style="background-image:url(/img/intro/social/history_thumb1.jpg)" title="희망2021 나눔캠페인 성금 기부 참여(KBS 보도)"></div>
                    <div class="swiper-slide" data-index="20" style="background-image:url(/img/intro/social/history_thumb2.jpg)" title="코리아런드리와 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="19" style="background-image:url(/img/intro/social/history_thumb3.jpg)" title="롯데마트와 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="18" style="background-image:url(/img/intro/social/history_thumb4.jpg)" title="고객이 신뢰하는 브랜드 대상 수상 (주최 : 한경비즈니스)"></div>
                    <div class="swiper-slide" data-index="17" style="background-image:url(/img/intro/social/history_thumb5.jpg)" title="한국소비자만족지수 1위 수상 (주최 : 한경비즈니스)"></div>

                    <div class="swiper-slide" data-index="16" style="background-image:url(/img/intro/social/history_thumb5.jpg)" title="KBS 불우이웃돕기 성금 방송보도"></div>
                    <div class="swiper-slide" data-index="15" style="background-image:url(/img/intro/social/history_thumb7.jpg)" title="인바이유와 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="14" style="background-image:url(/img/intro/social/history_thumb8.jpg)" title="현대아이씨티, 나이스디앤비와 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="13" style="background-image:url(/img/intro/social/history_thumb9.jpg)" title="두물머리투자자문과 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="12" style="background-image:url(/img/intro/social/history_thumb10.jpg)" title="대한민국고객만족브랜드대상 수상 (주최 : 주간동아)"></div>

                    <div class="swiper-slide" data-index="11" style="background-image:url(/img/intro/social/history_thumb11.jpg)" title="한국고객만족도 1위 수상 (주최 : 한경비즈니스)"></div>
                    <div class="swiper-slide" data-index="10" style="background-image:url(/img/intro/social/history_thumb11.jpg)" title="사내 이웃돕기 모금운동 (KBS 보도)"></div>
                    <div class="swiper-slide" data-index="9" style="background-image:url(/img/intro/social/history_thumb11.jpg)" title="(계속) 연말 KBS ‘희망나눔’ 이웃돕기 전 직원 성금 기탁"></div>
                    <div class="swiper-slide" data-index="8" style="background-image:url(/img/intro/social/history_thumb11.jpg)" title="랭키닷컴 재무설계/컨설팅 분야 1위 선정 (주최 : 랭키닷컴)"></div>
                    <div class="swiper-slide" data-index="7" style="background-image:url(/img/intro/social/history_thumb15.jpg)" title="‘찾아가는 경영컨설팅’ 중소·중견기업 리스크관리 세미나 실시"></div>

                    <div class="swiper-slide" data-index="6" style="background-image:url(/img/intro/social/history_thumb15.jpg)" title="이노비즈협회와 업무제휴협약 체결"></div>
                    <div class="swiper-slide" data-index="5" style="background-image:url(/img/intro/social/history_thumb17.jpg)" title="중소기업 경영자 대상 CEO골프클래식 개최 및 참가비 기부"></div>
                    <div class="swiper-slide" data-index="4" style="background-image:url(/img/intro/social/history_thumb17.jpg)" title="세월호 유가족 대상 전 직원 성금 기탁"></div>
                    <div class="swiper-slide" data-index="3" style="background-image:url(/img/intro/social/history_thumb17.jpg)" title="강화군 불우이웃돕기 및 사회복지시설 지원 성금 기탁"></div>
                    <div class="swiper-slide" data-index="2" style="background-image:url(/img/intro/social/history_thumb17.jpg)" title="서울사회복지공동나눔회를 통해 ‘저소득 가정지원’ 매월 실시"></div>

                    <div class="swiper-slide" data-index="1" style="background-image:url(/img/intro/social/history_thumb17.jpg)" title="동작복지재단을 통한 ‘한부모 가정지원’"></div>
                    <div class="swiper-slide" data-index="0" style="background-image:url(/img/intro/social/history_thumb17.jpg)" title="KBS, MBC ‘사랑의 열매’ 성금 기탁 -->"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- //history -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/intro/social.scss';
</style>