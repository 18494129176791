<template>
    <main id="container">
        <section id="visual">
            <h3>피플라이프의 재무현황을 알려드립니다.</h3>
        </section>
        <section id="finance_list">
            <div class="inner">
                <div class="tit_box">
                    <h3 class="title">재무현황공시</h3>
                    <p class="desc_section">피플라이프의 주요 실적자료를 확인하실 수 있습니다.</p>
                </div>
                <div class="board_list">
                    <div class="board_menu">
                        <span>No.</span>
                        <span>제목</span>
                        <span>등록일</span>
                    </div>
                    <div class="list">
                        <ul>
                            <!-- /finance/list.json -->
                            <li class="item hidden">
                                <a href="javascript:void(0)">
                                    <i data-type="num"></i>
                                    <em data-type="title"></em>
                                    <p data-type="date"></p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
			</div>
        </section>
    </main>
</template>

<script>
    export default {}
</script>

<style scoped lang="scss">
	@import '@/assets/scss/intro/finance.scss';
</style>