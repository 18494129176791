<template>
    <footer  data-auto-load="false">
        <div class="footer_top">
			<div class="inner">
				<ul class="list_award">
					<li class="item_award">
						<p class="year">2024</p>
						<p class="tit">프리미엄<br>브랜드대상</p>
						<p class="host">(주최 : 주간동아)</p>
					</li>
					<li class="item_award">
						<p class="year">2021</p>
						<p class="tit">대한민국 소비자<br>선호도 1위</p>
						<p class="host">(주최 : 주간동아)</p>
					</li>
					<li class="item_award">
						<p class="year">2년연속(2019-20)</p>
						<p class="tit">한국소비자만족지수<br>1위</p>
						<p class="host">(주최 : 한경비즈니스)</p>
					</li>
					<li class="item_award">
						<p class="year">2020</p>
						<p class="tit">고객이신뢰하는<br>브랜드 대상</p>
						<p class="host">(주최 : 한경비즈니스)</p>
					</li>
					<li class="item_award">
						<p class="year">2019</p>
						<p class="tit">대한민국고객만족<br>브랜드 대상</p>
						<p class="host">(주최 : 주간동아)</p>
					</li>
					<li class="item_award">
						<p class="year">2019</p>
						<p class="tit">한국고객만족도<br>1위</p>
						<p class="host">(주최 : 한경비즈니스)</p>
					</li>
				</ul>
			</div>
		</div>
        <!-- 금소법가이드 -->
        <div id="financial_guide"></div>
        <!-- //금소법가이드 -->
        <div class="footer_bottom">
            <div class="inner clearfix">
                <div class="footer_logo float-left">
                    <a href="/"><img src="" data-images-path="/image/logo/peoplelife_basic.svg" alt="로고"></a>
                </div>
                <div class="info float-right clearfix">
                    <div class="float-left">
                        <ul class="footer_gnb clearfix">
                            <li><a class="color-3cb8e0" href="javascript:void(0)" onclick="LAYER('policy_privacy')">개인정보처리방침</a></li>
                            <li><button type="button" onclick="LAYER('policy_usege')">이용약관</button></li>
                            <li><button type="button" onclick="LAYER('policy_credit')">신용정보활용체제공시</button></li>
                            <!-- <li><button type="button" data-target="#external_modal">외부감사인선임공고</button></li> -->
                            <li><a href="javascript:void(0)" onclick="LAYER('policy_protection')">금융소비자보호기준</a></li>
                            <!-- <li class="ml-0 no-boarder"><button type="button" data-target="#temporary_shareholder_closure_modal">임시 주주총회 기준일 및 주주명부 폐쇄기간 공고</button></li> -->
                            <!-- <li class="ml-0 no-boarder"><button type="button" data-target="#shareholder_guide_modal">주주 보호 및 조치사항 안내</button></li> -->
                            <!-- <li class="ml-0 no-boarder"><button type="button" data-target="#shareholder2_modal">기준일 및 주주명부 폐쇄 공고</button></li> -->
                            <!-- <li class="ml-0 no-boarder"><button type="button" data-target="#agent_modal">명의개서대리인 선임</button></li> -->
							<!-- <br> -->
                            <!-- <li><button type="button" data-target="#shareholder_modal">구주권제출 및 이의제출공고</button></li> -->
                            <!-- <li><button type="button" data-target="#shareholder_modal">주주명부폐쇄공고</button></li> -->
                        </ul>
                        <ul class="footer_gnb clearfix">
                            <li class="ml-0 no-border"><button type="button" onclick="LAYER('policy_customer_info')">고객정보 취급방침</button></li>
                        </ul>
                        <address>
                        <p><span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span></p>
                        <p><span data-peoplelife="description"></span> ㅣ <button class="agency_certificate" style="text-decoration: underline;">대리점등록증</button></p>
                        <p>대표이사 : <span data-peoplelife="owner"></span> ㅣ 사업자등록번호 : <span data-peoplelife="corpNumber"></span></p>
                        <p class="review" data-thispage="deliberation"></p>
                        <p>본 광고는 광고심의기준을 준수하였으며, 유효기간은 심의일로부터 1년입니다.</p>
                        <p class="copyright">Copyright2020. Peoplelife all rights reserved.</p>
                        </address>
                    </div>
                    <div class="infobox_right float-right">
                        <div class="info_buttons">
                            <button type="button" class="check_inquiry" onclick="LAYER('checkInquiry')">방문판매원 명부 조회</button>
                            <button type="button" class="contact_prohibition" onclick="LAYER('contactProhibition')">연락금지요구권 안내</button>
                        </div>
                        <a href="/brand/Ceo#vision" class="counselbtn">상담신청</a>
                        <div class="footer_select">
                            <button class="siteBtn">family site</button>
                            <div class="siteBox">
                                <ul>
                                    <li><a href="//bohumclinic.com/shop/" target="_blank">보험클리닉</a></li>
                                    <li><a href="//bohumclinic.com/visit/" target="_blank">개인영업(FA)</a></li>
                                    <!-- <li><a href="//ceo-clinic.co.kr/" target="_blank">CEO클리닉</a></li> -->
                                </ul>
                            </div>
                        </div>
                        <ul class="footer_call clearfix">
                            <li>
                                <p>본사 대표전화</p>
                                <div class="call_number"><span data-peoplelife="callCenter"></span></div>
                            </li>
                            <li>
                                <p>보험클리닉</p>
                                <div class="call_number">1833-6114</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <i class="topBtn" data-target="#wrap" data-focus=""></i>
    </footer>
</template>


<script>
export default {}
</script>