<!-- 컨설팅 프로세스 - OTC -->
<template>
    <section id="processSwiper" class="section">
        <div class="inner">
            <h3 class="tit_section">법인고객 컨설팅 신청</h3>
            <p class="desc_section">중소기업을 경영할 때에는 여러 가지 체감할 수 있는 위험 요소가 산재합니다.<br> 풍부한 컨설팅 경험이 있는 재무보좌관이 경영 리스크를 최소화할 수 있는<br> 솔루션을 제공합니다.</p>
            <i class="bar_tit"></i>

            <div class="swiper-container swiper-step">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><span>상담신청</span></div>
                    <div class="swiper-slide"><span>재무보좌관<br>지정</span></div>
                    <div class="swiper-slide"><span>기업 검진</span></div>
                    <div class="swiper-slide"><span>솔루션 제안</span></div>
                    <div class="swiper-slide"><span>전문가 구성</span></div>
                    <div class="swiper-slide"><span>솔루션 실행</span></div>
                    <div class="swiper-slide"><span>사후 관리</span></div>
                </div>
            </div>

            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP1. 상담 신청</p>
                            <p class="desc">원하시는 상담 분야를 선택하시면 담당자가 확인한 후 연락을 드립니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP2. 재무보좌관 지정</p>
                            <p class="desc">각 분야별로 특화된 전문가가 고객님의 기업 상황에 맞는 분야로 지정됩니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP3. 기업검진</p>
                            <p class="desc">기업 정보를 분석한 빅데이터를 기반으로 기업을 진단합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP4. 솔루션 제안</p>
                            <p class="desc">기업의 현재 상태와 여러가지 위험 요인들을 감안하여<br> 적합한 솔루션을 제안합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP5. 전문가 구성</p>
                            <p class="desc">적절한 솔루션에 따라 세무·법무·노무·부동산·금융·M&A 등<br> 각 분야별 전문가가 상담합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP6. 솔루션 실행</p>
                            <p class="desc">구성된 전문가가 개별 기업에 꼭 맞는 <br> 맞춤형 솔루션을 실행합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP7. 사후관리</p>
                            <p class="desc">실행 후 지속적인 관리로 기업의 경영 리스크를 최소화하는 솔루션을 제공합니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb1.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb2.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb3.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb4.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb5.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb6.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/otc/thumb7.jpg" alt="사진"></div>
                </div>
            </div>
            
            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_top01.svg" alt="위로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_bottom01.svg" alt="아래로"></button>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'processSwiper01',    
}
</script>



<style lang="scss">
    #processSwiper {
        position:relative;padding:80px 0 0;
        .tit_section {
            margin-bottom:70px;font-size:38px;font-weight:500;text-align:center;line-height:1.3em;letter-spacing:-0.04em;
        }
        .desc_section {
            position:absolute;top:380px;left:620px;font-size:16px;color:#666666;
        }
        .bar_tit {
            position:absolute;top:567px;right:645px;z-index:11;width:0;height:2px;background-color:#0f95d7;transition:all 0.3s ease-in;
            &.on {
                width:110px;transition:all 0.4s ease-out;
            }
        }
        .swiper-top {
            position:absolute;top:500px;right:75px;z-index:10;width:650px;height:250px;background-color:#fff;box-shadow:0 0 10px rgba(#000, 0.09);
            .swiper-slide {
                padding:55px 0 0 95px;
                .tit {
                    position:relative;margin-bottom:50px;font-size:24px;font-weight:500;color:#0f95d7;line-height:1em;
                }
                .desc {
                    font-size:16px;color:#666;
                }
            }
        }
        .swiper-thumb {
            width:530px;height:530px;margin:0;
        }
        .swiper-step {
            counter-reset:slide;
            overflow:visible;margin-bottom:60px;
            // &::before {
            //     content:"";position:absolute;top:50%;left:-370px;z-index:-1;width:370px;height:2px;background-color:#3a478f;;
            // }
            &::after {
                content:"";position:absolute;top:50%;left:50%;z-index:-1;width:200%;height:2px;background-color:#ebebeb;transform:translate(-50%, -50%);
            }
            .swiper-slide {
                counter-increment:slide;
                position:relative;width:150px;height:150px;padding-top:45px;color:#c5c7c8;border:1px solid #797b7d;border-radius:100%;text-align:center;background-size:cover;cursor:pointer;transition:border 0.6s 0.2s ease-in-out,box-shadow 0.6s ease-in-out;transform:scale(0.6);
                &::before {
                    content:counter(slide);position:absolute;top:-70px;right:-10px;z-index:-1;font-family:"BrushScriptMT",serif;font-size:78px;color:#f7f7f7;
                }
                &::after {
                    content:"";position:absolute;top:0;bottom:0;left:0;right:0;border-radius:100%;background-color:#000;opacity:0.4;transition:all 0.4s ease-in-out;
                }
                span {
                    display:block;;position:relative;z-index:2;line-height:1.35em;
                    &::before {
                        content:"STEP 0"counter(slide);display:block;position:relative;z-index:2;margin-bottom:13px;line-height:1em;font-family:"Roboto",sans-serif;font-size:14px;letter-spacing:0.04em;
                    }
                }
                &.swiper-slide-thumb-active {
                    border-color:#3a478f;color:#fff;box-shadow:3px 3px 10px rgba(#000, 0.3);
                    &::after {
                        background-color:#3a478f;opacity:1;
                    }
                }
            }
        }
        .swiper-nav {
            position:absolute;top:640px;right:0;
            .swiper-pagination {
                left:auto;top:50%;bottom:auto;right:10px;width:auto;white-space:pre;transform:translateY(-50%);user-select:none;
                span {
                    font-family:"Roboto",sans-serif;font-size:16px;font-weight:300;color:#797979;
                    &.swiper-pagination-current {
                        color:#000;font-weight:bold;
                    }
                }
            }
            .swiper-button {
                left:auto;right:0;z-index:11;width:44px;height:44px;border:1px solid #ccc;border-radius:100%;background:#fff;
                &.swiper-button-prev {
                    margin-top:-83px;
                }
                &.swiper-button-next {
                    margin-top:39px;
                }
                svg {
                    fill:#000;position:absolute;top:50%;left:50%;width:15px;height:auto;transform:translate(-50%, -50%);
                }
            }
        }
        &.appear {
            .swiper-step {
                .swiper-slide {
                    @for $i from 1 through 7 {
                        &:nth-child(#{$i}) {
                            transform:scale(1);transition:all 0.6s #{$i * 0.1 + 0.2}s ease-in-out;
                        }
                    }
                }
            }
        }
    }
</style>