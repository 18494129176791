<!-- 2020년 수상내역 -->
<template>
    <section id="award2020" class="section">
        <div class="inner">
            <h3 class="tit_section">믿을 수 있는, <strong>피플라이프<i></i><i></i><i></i><i></i></strong></h3>
            <p class="desc_section">피플라이프는 고객님의 만족을 위해 항상 노력하고 있습니다.<br> 앞으로도 더 큰 신뢰와 결실로 보답하겠습니다. </p>
            <img src="/img/common/contents/award/2020/contents.png" alt="">
            <ul class="list_award hidden">
                <li class="item_award">
                    <img class="medal" src="/img/common/contents/award/2020/medal_2019_kcsi.png" alt="2019 한국고객만족도 1위">
                    <img class="laurus" src="/img/common/contents/award/2020/laurus.png" alt="수상이미지">
                    <p class="tit">2019 한국고객만족도 <strong>1위</strong><br>(주최 : 한경비즈니스)</p>
                </li>
                <li class="item_award">
                    <img class="medal" src="/img/common/contents/award/2020/medal_2019_kcsb.png" alt="2019 고객만족브랜드 대상">
                    <img class="laurus" src="/img/common/contents/award/2020/laurus.png" alt="수상이미지">
                    <p class="tit">2019 고객만족브랜드 <strong>대상</strong><br>(주최 : 주간동아)</p>
                </li>
                <li class="item_award">
                    <img class="medal" src="/img/common/contents/award/2020/medal_2020_tba.png" alt="2020 고객이신뢰하는브랜드 대상">
                    <img class="laurus" src="/img/common/contents/award/2020/laurus.png" alt="수상이미지">
                    <p class="tit">2020 고객이신뢰하는브랜드 <strong>대상</strong><br>(주최 : 한경비즈니스)</p>
                </li>
                <li class="item_award">
                    <img class="medal" src="/img/common/contents/award/2020/medal_2020_ksci.png" alt="2020 한국소비자만족지수 대상">
                    <img class="laurus" src="/img/common/contents/award/2020/laurus.png" alt="수상이미지">
                    <p class="tit">2년연속(2019-20)<br>한국소비자만족지수<strong>1위</strong><br>(주최 : 한경비즈니스)</p>
                </li>
                <li class="item_award">
                    <img class="medal" src="/img/common/contents/award/2020/medal_2021_kcpi.png" alt="2021 대한민국 소비자 선호도 1위">
                    <p class="tit">2021 대한민국 소비자 선호도 <strong>1위</strong><br>(주최 : 주간동아)</p>
                </li>
                <li class="item_award">
                    <img class="medal" src="/img/common/contents/award/2020/medal_2021_kcpi.png" alt="2021 대한민국 소비자 선호도 1위">
                    <img class="shadow" src="/img/common/contents/award/2020/medal_bluer.png" alt="수상이미지">
                    <p class="tit">2021 대한민국 소비자 선호도 <strong>1위</strong><br>(주최 : 주간동아)</p>
                </li>
            </ul>
        </div>
    </section>
</template>



<script>
export default {
    name: 'award2020',
}
</script>



<style lang="scss"> 
    #award2020 {
        background-image:url(/img/common/contents/award/2020/bg.jpg);background-size:cover;
        .inner {padding: 0;}
        .tit_section {
            position:absolute;top:70px;left:360px;font-size:38px;font-weight:500;color:#fff;line-height:1em;text-shadow:2px 2px 10px rgba(#000, 0.8);
            strong {
                position:relative;
                i {
                    $i: 0;
                    $position : 12px, 50px, 90px, 130px ;
                    @each $left in $position {
                        $i: $i + 1;
                        &:nth-child(#{$i}) {
                            $name: inline-#{unique-id()};
                            position:absolute;top:-38px;left:$left;width:32px;height:30px;background-image:url(/img/common/contents/award/2020/star.png);animation:$name 1s #{$i * 0.2}s alternate infinite ease-in-out;
                            @keyframes #{$name} {
                                50%, 100% {transform:translateY(10px)}
                            }
                        }
                    }
                }
            }
        }
        .desc_section {
            position:absolute;top:65px;left:770px;font-size:16px;color:#e3e3e3;text-shadow:2px 2px 3px rgba(#000, 0.8);
        }
        .list_award {
            position:absolute;left:50px;top:180px;width: 100%; height: 100%;
            .item_award {
                position:absolute;top:0; transform: translateX(-50%);
                &:nth-child(1) {
                    left: 90%;
                }
                &:nth-child(2) {
                    left: 75%;
                }
                &:nth-child(3) {
                    left: 60%;
                }
                &:nth-child(4) {
                    left: 45%;
                }
                &:nth-child(5) {
                    left: 30%;
                }
                &:nth-child(6) {
                    top:-110px;left: 10%;
                    .shadow {
                        position:absolute;top:-20px;left:-20px;z-index:-1;opacity:0;
                    }
                    .tit {
                        bottom:-57px;
                    }
                }
                .medal {
                    opacity:0;transform:translateY(10px);transition:none;
                }
                .laurus {
                    position:absolute;top:-8px;left:-33px;z-index:-1;
                }
                .tit {
                    position:absolute;bottom:-105px;left:50%;width:200%;color:#fff;text-align:center;transform:translateX(-50%);
                }
            }
        }
        &.appear {
            .item_award {
                @for $i from 1 through 6 {
                    &:nth-child(#{$i}) {
                        .medal {
                            width: 150px;
                            opacity:1;transform:translateY(0);transition:all 0.6s #{1.2- ($i * 0.3)}s ease-in-out;
                        }
                    }
                }
                &:nth-child(6) {
                    .medal {
                        width: auto;
                    }
                    .shadow {
                        opacity:1;transition:all 0.6s 1.8s ease-in-out;
                    }
                }
            }
        }
    }
</style>