<template>
<!-- main -->
<main id="container">

    <!-- 페이지명 -->
    <section id="pageName">
        <div class="inner clearfix">
            <h3 class="tit_section">뉴스</h3>
            <p class="desc_section">피플라이프에서 전하는 경영관련 보도자료 입니다.</p>
        </div>
    </section>
    <!-- 페이지명 -->

    <!-- 최신소식 -->
    <section id="topview" class="section hidden">
        <div class="inner clearfix">
            <a class="link_thumb" href="kavascript:;">
                <div class="thumb">
                    <img src="" alt="썸네일" onerror="">
                </div>
            </a>
            <div class="news_info">
                <p class="cate"></p>
                <p class="tit"><a class="link_news" href="kavascript:;"></a></p>
                <p class="desc"><a class="link_news" href="kavascript:;"></a></p>
                <p class="date"></p>
                <i class="ico_more"><a href="kavascript:;"><img src="" data-images-path="/image/svg/plus01.svg" alt=""></a></i>
            </div>
        </div>
    </section>
    <!-- //최신소식 -->

    <!-- 전체보기 -->
    <section id="allnews" class="section">
        <div class="inner clearfix">
            <div class="formNews formRow clearfix" data-type="news">
                <label class="select_wrap">
                    <select name="news" class="news">
                        <option value="">전체</option>
						<option value="소식">소식</option>
						<option value="서비스">서비스</option>
						<option value="보험클리닉">보험클리닉</option>
						<option value="CEO클리닉">CEO클리닉</option>
						<option value="수상">수상</option>
						<option value="기타">기타</option>
                    </select>
                    <i data-images-path="/image/common/icoSelect.png"></i>
                </label>
            </div>
            <ul class="list_news">
                <li class="item_news">
                    <a class="link_news" href="kavascript:;">
                        <p class="cate"></p>
                        <p class="tit"></p>
                        <p class="date"></p>
                        <div class="thumb">
                            <img src="" alt="썸네일" onerror="">
                        </div>
                    </a>
                </li>
            </ul>
            <div class="more_wrap">
                <button class="btn_more" title="더보기" type="button"><img src="" data-images-path="/image/svg/plus02.svg" alt=""></button>
            </div>
        </div>
    </section>
    <!-- //전체보기 -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/news.scss';
</style>
