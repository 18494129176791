<!-- 컨설팅 프로세스 - FA -->
<template>
    <section id="processSwiper" class="section">
        <div class="inner">
            <h3 class="tit_section">고객 맞춤형 컨설팅 프로세스</h3>
            <p class="desc_section">피플라이프만의 지능형 보장분석 시스템으로 보험비교부터 맞춤형 보장분석까지<br> 원스톱 통합지원시스템을 제공합니다.</p>
            <i class="bar_tit"></i>

            <div class="swiper-container swiper-step">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><span>상담신청</span></div>
                    <div class="swiper-slide"><span>보험전문가<br> FA배정</span></div>
                    <div class="swiper-slide"><span>고객상담<br> (고객 분석)</span></div>
                    <div class="swiper-slide"><span>상품비교 및<br> 분석</span></div>
                    <div class="swiper-slide"><span>전문가 네트워크<br> 지원</span></div>
                    <div class="swiper-slide"><span>맞춤 포트폴리오<br> 제안</span></div>
                    <div class="swiper-slide"><span>상담 후<br> 평생관리 </span></div>
                </div>
            </div>
            
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP1. 상담 신청</p>
                            <p class="desc">상담을 원하시는 고객님의<br>기본적인 정보를 등록해주세요.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP2. 보험전문가 FA 지정</p>
                            <p class="desc">수 많은 경험과 실전 지식을 보유한<br>고객 맞춤 보험전문가 FA가 지정됩니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP3. 고객상담(고객분석)</p>
                            <p class="desc">고객님이 현재 보유한 보험의 보장 및 보험료가 적정한지 확인합니다.<br> 투자 성향, 재무상황 및 미래의 계획을 상담하고<br>고객님의 보험 및 자산을 분석합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP4. 상품 비교 및 분석</p>
                            <p class="desc">피플라이프만의 지능형 보장분석시스템<br> 어시스트(@ssit)를 통해 국내 주요 보험사의<br>상품을 비교분석 합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP5. 자산관리 컨설팅 지원</p>
                            <p class="desc">CFP팀이 고객님의 증권을 분석하고<br>금융연구소에서 보험 비교를 넘어 생애 전반의<br>라이프 케어 및 자산관리 컨설팅을 지원합니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP6. 맞춤 포트폴리오 제안</p>
                            <p class="desc">차별화된 상품 포트폴리오로 ‘보험사’가 아닌<br> 고객님에게 유리한 상품만을 추천한 맞춤<br>포트폴리오를 제안합니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="tit">STEP7. 상담 후 평생관리</p>
                            <p class="desc">보험금 청구 시스템 및 지속 관리를 통해<br>고객 편의성을 증대시키고, 정기적인 검진을 통해<br>고객 만족을 위해 노력합니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb1.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb2.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb3.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb4.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb5.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb6.jpg" alt="사진"></div>
                    <div class="swiper-slide"><img src="/img/common/contents/ProcessSwiper/fa/thumb7.jpg" alt="사진"></div>
                </div>
            </div>            

            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_top01.svg" alt="위로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_bottom01.svg" alt="아래로"></button>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'processSwiper02',    
}
</script>



<style lang="scss">
    //processSwiper01.vue 에서 수정하세요
</style>