<template>
<!-- main -->
<main id="container">

    <!-- 페이지명 -->
    <section id="pageName">
        <div class="inner">
            <h3 class="tit_section">웹진</h3>
            <p class="desc_section">웹진으로 만나는 재무보좌관 이야기</p>
        </div>
    </section>
    <!-- 페이지명 -->

    <!--  이달의 웹진 -->
    <section id="webzine" class="section">
		<div class="inner">
            <div class="content clearfix">
                <div class="area_left">
                    <div class="top">
                        <em class="date_view"></em>
                        <div class="date_select">
                            <label>
                                <select name="webzineDateYear" id="webzine_year">
                                    <!-- <option value="2021">2021년</option> -->
                                </select>
                                <u></u>
                            </label>
                            <label>
                                <select name="webzineDateMonth" id="webzine_month">
                                    <!-- <option value="01">01월</option> -->
                                </select>
                                <u></u>
                            </label>
                        </div>
                    </div>
                    <div class="thumb">
                        <img src="">
                    </div>
                    <div class="bottom">
                        <a href="" target="_blank" class="open">웹진 보기</a>
                    </div>
                </div>
                <div class="area_right">
                    <div class="content_view">
                        <a href="" target="_blank" class="open">
                            <img src="">
                        </a>
                    </div>
                </div>
            </div>
        </div><!-- / .inner -->
        <div class="webzine_bg"></div>
	</section>
    <!--  //이달의 웹진 -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/promotion/webzine.scss';
</style>
