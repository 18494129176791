<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <div class="txt">
                <em>기업컨설팅</em>
               <strong>중소 사업자를 위한<br>지속 가능한 가이드</strong>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section id="information" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><img src="/img/sales/corporation/information_title.png" alt="EFA"></h3>
            <div class="thumb">
                <img src="/img/sales/corporation/information_thumb.jpg" alt="사진">
            </div>
            <div class="info">
                <p class="txt-top">
                    경영리스크로 골치 아픈 순간!
                    <br> 중소기업 위기극복 파트너가
                    <br> 도와드리겠습니다.
                </p>
                <p class="txt-bottom">
                    중소 사업자는 예기치 못한 경영리스크로 한순간에 쌓아온 모든 것을 잃을 수 있습니다. <br>
                    리스크 관리에서 사후관리까지 오랜 기간 시행착오를 겪으며 쌓아온 집약된 노하우로<br>
                    중소기업에 꼭! 필요한 솔루션을 제시해 드립니다.
                </p>
            </div>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //주요정보 -->

    <!-- 차별화 서비스 -->
    <section id="differentiation" class="section">
        <div class="inner">
            <h3 class="tit_section"> 기업컨설팅의 차별화 된 서비스 <br> 「 RISK Solution 」 </h3>
            <p class="desc_section"> 중소기업 경영 시 발생할 수 있는 위험요소!<br> 전속자문 법인과 풍부한 컨설팅 경력을 통해 리스크를 최소화하는 솔루션을 제공합니다. </p>
            <ul class="list">
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>경정청구를 통한 세금 환급</mark></strong>
                    </div>
                    <div class="desc">
                        <p>5년간 더 낸 세금<br> 환급하는 서비스</p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>투자수익률 이익금환원</mark><br> <mark>솔루션</mark></strong>
                    </div>
                    <div class="desc line1">
                        <p>법인과 개인의 소득 분산 등 활용</p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>경영효율화 핵심 전략</mark></strong>
                    </div>
                    <div class="desc line2">
                        <p> 인사노무, 인증, 연구소, 특허 등 활용</p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>경영리스크 해결 및 관리</mark></strong>
                    </div>
                    <div class="desc">
                        <p> 명의신탁주식, 가지급금, 가수금,<br> 임원의 순직 등 리스크 대비 </p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>재무구조 분석</mark></strong>
                    </div>
                    <div class="desc">
                        <p> 정관 제도정비, 재무구조 개선,<br> 기업 예비 진단 등 체크 </p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>가업승계와 효율적인 전략</mark></strong>
                    </div>
                    <div class="desc">
                        <p> 2023년 승계요건 완화와 <br> 공제액 상향 등 활용 </p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>중소기업 M&A</mark></strong>
                    </div>
                    <div class="desc">
                        <p> 인수합병 활성화로 <br>새로운 성장 동력  확보 </p>
                    </div>
                </li>
                <li>
                    <i></i>
                    <div class="title">
                        <strong><mark>사내근로복지기금</mark></strong>
                    </div>
                    <div class="desc">
                        <p> 기업복지제도를 활용한 <br> 근로자의 복지혜택 제공 </p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- //차별화 서비스 -->

    <!-- 회사연혁 -->
    <section id="history" class="section">
        <h3 class="tit_section"> 기업과 함께 동반 상승하는 착한 회사,<br> 신뢰할 수 있는 피플라이프 </h3>
        <p class="desc_section">대한민국 중소사업자와 동반 상승하는 피플라이프 <br> 5년, 10년, 20년 후에도 기업이 존속할 수 있도록 지속적인 서비스를 제공합니다.</p>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2024</p>
                        <p class="desc">
                            M보험클리닉 신규 론칭<br>
                            보험클리닉 신규 광고<br>
                            2024 프리미엄 브랜드 대상 수상
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2023</p>
                        <p class="desc">
                            한화그룹편입<br>
                            이브이케어와 MOU 체결<br>
                            보험클리닉 신규 광고(현빈)<br>
                            2023 만나 Show 행사 진행
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2021</p>
                        <p class="desc">
                            매출액 3,135억 <br>
                            기업고객 99,335 <br>
                            공중파 광고(현빈) <br>
                            한국M&A거래소 업무제휴
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2019</p>
                        <p class="desc">
                            ‘CEO클리닉’ 브랜드 런칭<br>
                            공중파 광고(현빈)
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2018</p>
                        <p class="desc">
                            매출 2,000억원 돌파<br>
                            ‘보험클리닉’ 런칭
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2017</p>
                        <p class="desc">
                            기업고객 40,000개 돌파<br>
                            매출 1,400억
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2016</p>
                        <p class="desc">매출 1,200억 초과</p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2015</p>
                        <p class="desc">
                            중소기업 CEO, 골프 클래식 개최,<br>
                            기업고객 30,000개 
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2014</p>
                        <p class="desc">
                            매경경영지원본부 론칭,<br>
                            매경미디어그룹과 업무제휴 
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2013</p>
                        <p class="desc">
                            GA 업계 최초<br>
                            공중파 광고 (조인성) 
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2012</p>
                        <p class="desc">
                            매출 660억,<br>
                            기업컨설팅 고객 9,000개 돌파
                        </p>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="swiper-slide">
                    <div class="box">
                        <p class="tit">2003</p>
                        <p class="desc"> 회사설립 </p>
                    </div>
                    <i class="border"></i>
                </div>
            </div>
        </div>
        <span class="cursor">
            <i></i>
        </span>
    </section>
    <!-- //회사연혁 -->

    <!-- 2020년 수상내역 -->
    <award2020></award2020>
    <!-- //2020년 수상내역 -->

    <!-- 전문가 네트워크 -->
    <networkTab />
    <!-- //전문가 네트워크 -->

    <!-- 사회 흐름 -->
    <roadMap />
    <!-- //사회 흐름 -->

    <!-- CEO 브로슈어 -->
    <section id="ceoBrochure" class="section">
        <div class="inner">
            <h3 class="tit_section">
                <p><img class="bi" src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="로고"> <span>Brochure</span></p>
                <p>중소기업을 위한<br><strong>경영효율화 컨설팅</strong></p>
            </h3>
            <p class="desc_section">기업경영시 코칭이 필요한 이유를 지금 확인해보세요</p>
            <a class="link_download" href="" data-json-path="/pdf/brochure2024.pdf" target="_blank" download><span>브로슈어 보기</span><img src="" data-images-path="/image/svg/plus01.svg" alt="다운로드"></a>
            <a class="link_book" href="" data-json-path="/pdf/brochure2024.pdf" target="_blank" download>
                <img class="book" :src="imgPath+'ceoBrochure_book.png?v=231123'" alt="책표지">
            </a>
        </div>
    </section>
    <!-- //CEO 브로슈어 -->

    <!-- 코칭서비스 -->
    <section id="coaching" class="section">
        <div class="inner">
            <div class="box_info">
                <img class="thumb" src="/img/sales/corporation/coaching_info_thumb.jpg" alt="사진">
                <div class="txt_info">
                    
                    <p class="tit">깊이 있는 안목, 폭넓은 전문성을 갖춘<br> <strong>경영효율화</strong> 컨설팅</p>
                    <p class="desc">마땅히 지켜야 할 법!<br> 위반하지 않고 법 테두리 안에서<br> 최적의 <strong>‘자산관리 프로그램’</strong> 솔루션을 선택하는 것 입니다. </p>
                </div>
            </div>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //코칭서비스 -->


    <!-- 전국 서비스 -->
    <section id="service" class="section">
        <div class="inner clearfix">
            <div class="left">
                <div class="map">
                    <dl class="list_map seoul pos_left">
                        <dt>
                            <em>서울</em>
                            <!-- <strong>46</strong> -->
                        </dt>
                    </dl>
                    <dl class="list_map kangwon pos_right">
                        <dt>
                            <em>강원</em>
                            <!-- <strong>4</strong> -->
                        </dt>
                        <!-- <dd><em>원주</em><strong>2</strong></dd>
                        <dd><em>춘천</em><strong>1</strong></dd> -->
                    </dl>
                    <dl class="list_map  gyeonggi pos_right">
                        <dt>
                            <em>경기</em>
                            <!-- <strong>11</strong> -->
                        </dt>
                        <!-- <dd><em>인천</em><strong>1</strong></dd>
                        <dd><em>일산</em><strong>1</strong></dd>
                        <dd><em>성남</em><strong>1</strong></dd>
                        <dd><em>안양</em><strong>1</strong></dd>
                        <dd><em>용인</em><strong>4</strong></dd> -->
                    </dl>
                    <dl class="list_map chungchung pos_left">
                        <dt>
                            <em>충청</em>
                            <!-- <strong>4</strong> -->
                        </dt>
                        <!-- <dd><em>충주</em><strong>1</strong></dd>
                        <dd><em>제천</em><strong>1</strong></dd>
                        <dd><em>대전</em><strong>3</strong></dd> -->
                    </dl>
                    <dl class="list_map jeolla pos_left">
                        <dt>
                            <em>전라</em>
                            <!-- <strong>5</strong> -->
                        </dt>
                        <!-- <dd><em>광주</em><strong>3</strong></dd> -->
                    </dl>
                    <dl class="list_map gyeongsang pos_right">
                        <dt>
                            <em>경상</em>
                            <!-- <strong>18</strong> -->
                        </dt>
                        <!-- <dd><em>구미</em><strong>1</strong></dd>
                        <dd><em>대구</em><strong>6</strong></dd>
                        <dd><em>울산</em><strong>2</strong></dd>
                        <dd><em>부산</em><strong>7</strong></dd>
                        <dd><em>해운대</em><strong>1</strong></dd> -->
                    </dl>
                    <dl class="list_map jeju pos_left">
                        <dt>
                            <em>제주</em>
                            <!-- <strong>1</strong> -->
                        </dt>
                    </dl>
                    <i class="cirlce seoul"></i>
                    <i class="cirlce kangwon"></i>
                    <i class="cirlce gyeonggi"></i>
                    <i class="cirlce chungchung"></i>
                    <i class="cirlce jeolla"></i>
                    <i class="cirlce gyeongsang"></i>
                    <i class="cirlce jeju"></i>
                    <img class="bg" src="/img/sales/corporation/service_map_bg.png" alt="">
                    <img class="bg_line" src="/img/sales/corporation/service_map_bg_line.png" alt="">
                </div>
            </div>
            <div class="right">
                <h3 class="tit_section">전국 법인컨설팅 솔루션 「CEO CLINC」</h3>
                <div class="desc_section">
                    <p>피플라이프의 대표 법인컨설팅 브랜드 'CEO클리닉'은</p>
                    <p>세무사, 변호사, 노무사, 변리사, 부동산전문가 등 제휴된 여러 전문가 네트워크와 함께</p>
                    <p><s>'중소기업 경영효율화 법인컨설팅'</s> 이라는 새로운 분야를 개척함으로써</p>
                    <p>가장 합리적인 전략과 솔루션을 제시하고</p>
                    <p>각종 경영 리스크관리 및 경영 효율화 전략을 실행 제안합니다.</p>
                </div>
                <!-- <p class="count">총<span class="num">89</span>개 사업단</p>
                <p class="source">2024년 5월 사업단 현황</p> -->
            </div>
        </div>
    </section>
    <!-- //전국 서비스 -->

    <!-- 어플리케이션 -->
    <!-- <section id="ceoapp" class="section">
        <div class="inner">
            <h3 class="tit_section">내 손에서 이뤄지는 CEO위기극복 서비스 <span>「 CEO CLINIC 」</span></h3>
            <p class="desc_section">지속가능 경영을 위한 1:1 전문가 상담서비스</p>
            <a class="link_more" href="http://ceo-clinic.co.kr/h/5AQh7kHWen" target="_blank"><span>CEO클리닉 모바일 웹 바로가기 </span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
            <p class="cmt_section">모바일 전용 웹사이트이며, PC에서는 정상적으로 보이지 않을 수 있습니다.</p>
            <img class="device device1" src="/img/sales/corporation/ceoapp_device1.png" alt="어플 캡쳐">
            <img class="device device2" src="/img/sales/corporation/ceoapp_device2.png" alt="어플 캡쳐">
        </div>
    </section> -->
    <!-- //어플리케이션 -->

    <!-- 컨설팅 프로세스 - OTC -->
    <processSwiper01 />
    <!-- //컨설팅 프로세스 - OTC -->

    <!-- 상담신청  -->
    <section id="freeConsult" class="section">
        <div class="inner">
            <h3 class="tit_section">기업컨설팅 신청하기</h3>
            <div class="render_form"></div>
            <li class="row row_type row_css clearfix" data-row="type" data-check="true" data-check-pass="false" data-check-prop="consultType" data-check-comment="상담분야를 선택해주세요" data-inbound-prop="codeId">
                <label class="select_wrap">
                    <select name="consult" class="openSans consult select_css">
                        <option value="">상담분야 선택</option>
                        <option value="241">세무</option>
                        <option value="242">노무</option>
                        <option value="243">법률</option>
                        <option value="244">부동산</option>
                        <option value="245">금융</option>
                        <option value="229">기타</option>
                    </select>
                    <i data-images-path="/image/common/icoSelect.png"></i>
                </label>
            </li>
        </div>
    </section>
    <!-- //상담신청  -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">명실상부,  법인컨설팅의 명가!</p>
            <h3 class="tit_section">피플라이프와 함께 하세요!<br><strong>다음 성공의 주인공은 당신입니다.</strong></h3>
            <a class="link_about" href="/recruit/RecruitCorp"><span>법인컨설팅 인재채용 바로가기 </span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
            <img class="model" src="/img/sales/corporation/about_model.png" alt="모델">
        </div>
    </section>
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/corporation/'
        }
    },
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/sales/corporation.scss';
</style>
