<template>
<!-- main -->
<main id="container">

    <!-- 바로가기 -->
    <nav id="nav_container">
        <div class="inner">
            <div class="list_nav">
                <a class="link_nav anchor" data-target="#visual">메인</a>
                <a class="link_nav anchor" data-target="#bohumclinic"><span class="hide">보험클리닉 </span>현황</a>
                <a class="link_nav anchor" data-target="#service"><span class="hide">차별화 </span>서비스</a>
                <a class="link_nav anchor" data-target="#award"><span class="hide">신뢰받는 </span>수상<span class="hide">내역</span></a>
                <!-- <a class="link_nav anchor" data-target="#social"><span class="hide">생생한 보클 </span>SNS</a> -->
                <a class="link_nav anchor" data-target="#start"><span class="hide">SINCE </span>2018</a>
                <!-- <a class="link_nav anchor" data-target="#future"><span class="hide">보험클리닉의 </span>미래</a> -->
                <a class="link_nav anchor" data-target="#gate"><span class="hide">보험클리닉 </span>바로가기</a>
                <a class="link_nav anchor" data-target="#ci">CI<span class="hide"> 소개</span></a>
            </div>
        </div>
    </nav>
    <!-- //바로가기 -->

    <!-- 메인 비주얼 -->
    <section id="visual" class="section dataTarget" data-scroll-cut-line="10">
        <div class="swiper-container swiper-top">
            <!-- <p class="tit_container">보험이 많아도, 보험이 없어도<strong>내 보험 바로.잡는 보험클리닉</strong></p> -->
            <div class="swiper-wrapper">
                <div class="swiper-slide" data-video-src="/file/video/2024_tvcf2_search.mp4">
                    <div class="box_video" ></div>
                </div>
                <!-- <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2020_season2_2.mp4"><div class="box_video"></div></div> -->
                <!-- <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2019_season2_1.mp4"><div class="box_video"></div></div> -->
                <!-- <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2019_season2_2.mp4"><div class="box_video"></div></div> -->
                <!-- <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2019_season1_1.mp4"><div class="box_video" data-images-path="/video/tvcf/bohumclinic/2019_season1_1.jpg"></div></div>
                <div class="swiper-slide" data-video-src="/video/tvcf/bohumclinic/2019_season1_2.mp4"><div class="box_video" data-images-path="/video/tvcf/bohumclinic/2019_season1_2.jpg"></div></div> -->
            </div>
        </div>

        <div class="bottom">
            <div class="inner">
                <!-- <div class="swiper-container swiper-thumb ">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2020_season2_1.jpg"></div>
                        <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2020_season2_2.jpg"></div>
                        <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2019_season2_1.jpg"></div>
                        <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2019_season2_2.jpg"></div>
                        <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2019_season1_1.jpg"></div>
                        <div class="swiper-slide" data-images-path="/video/tvcf/bohumclinic/2019_season1_2.jpg"></div>
                    </div>
                </div> -->
                <!-- Add Navigation -->
                <!-- <button class="swiper-button-prev swiper-button-white" type="button"></button>
                <button class="swiper-button-next swiper-button-white" type="button"></button>               -->
                <div class="list_sns">
                    <p class="tit_list">보험클리닉SNS</p>
                    <a class="link_sns" href="https://www.youtube.com/channel/UCzcc4DNIpNngewyH0Lt1tpg" target="_blank" title="유투브 바로가기"><img src="" data-images-path="/image/sns/youtube01.svg" alt=""></a>
                    <!-- <a class="link_sns" href="https://www.facebook.com/bohumclinic" target="_blank" title="페이스북 바로가기"><img src="" data-images-path="/image/sns/facebook01.svg" alt=""></a> -->
                    <!-- <a class="link_sns" href="https://www.instagram.com/bohum_clinic" target="_blank" title="인스타그램 바로가기"><img src="" data-images-path="/image/sns/instagram01.svg" alt=""></a> -->
                    <a class="link_sns" href="https://blog.naver.com/bohum_clinic" target="_blank" title="블로그 바로가기"><img src="" data-images-path="/image/sns/blog01.svg" alt=""></a>
                </div>
            </div>
        </div>

        <div class="video_controlor">
            <button class="volume" type="button"></button>
        </div>

    </section>
    <!-- //메인 비주얼 -->

    <!-- 현황 -->
    <section id="bohumclinic" class="section dataTarget" data-scroll-cut-line="10">
        <h3 class="tit_section"><strong>보험클리닉</strong>은 오늘도 고객님의 궁금증을 해결하고 있습니다.</h3>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <span>예전에 놓쳤던<br> 보험금까지 받았어요~<br> 보험클리닉 덕에 돈 벌었어요!</span>
                </div>
                <div class="swiper-slide">
                    <span>보험증권 없이도<br> 가입한 보험이 뭐가 부족한지<br> 다 분석해줘요!</span>
                </div>
                <div class="swiper-slide">
                    <span>보험, 많이 들긴 했는데<br> 잘한 건지 확인하고 싶었어요!<br> 이제 마음이 놓여요! </span>
                </div>
                <div class="swiper-slide">
                    <span>불필요한건 빼주고<br> 모자란건 채워주고!<br> 꼭 보험리모델링 받으세요! </span>
                </div>
                <div class="swiper-slide">
                    <span>실비 청구에 대해 문의했는데,<br> 6시간 이상 응급실에 체류 시<br> 입원청구가 가능하다는 얘기에<br> 입원청구를 할 수 있었어요! </span>
                </div>
                <div class="swiper-slide">
                    <span>수 많은 보험을 직접 비교하는게<br> 불가능한데 보험클리닉에서<br> 손쉽게 가능하니! 추천합니다! </span>
                </div>
            </div>
        </div>
        <div class="group_count">
            <!-- <p class="tit_group"><strong>보험클리닉</strong>은 오늘도 고객님의 궁금증을 해결하고 있습니다.</p> -->
            <ul class="list_count">
                <li class="item_count">
                    <p class="tit">누적 방문자 수</p>
                    <p class="num"><span>0</span>명</p>
                    <p class="source">(자사 방문상담 데이터, <i class="ymd"></i> 기준)</p>
                </li>
                <li class="item_count">
                    <p class="tit">보험클리닉 지점 수</p>
                    <p class="num"><span>0</span>개</p>
                    <p class="source">(자사 지점 수 데이터, <i class="ymd"></i> 기준)</p>
                </li>
                <li class="item_count">
                    <p class="tit">상담예약 수</p>
                    <p class="num"><span>0</span>건</p>
                    <p class="source">(자사 상담예약 데이터, <i class="ymd"></i> 기준)</p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //현황 -->

    <!-- 서비스 -->
    <section id="service" class="section dataTarget" data-scroll-cut-line="10">
        <div class="inner">
            <h3 class="tit_section">보험클리닉에서만 경험할 수 있는 <strong>차별화 서비스</strong></h3>
            <p class="desc_section">
                <span class="slide1">보험클리닉은  <mark>고객님의 가장 가까이에서, 가장 믿고 의지할 수 있는 상담매니저가</mark>,<br> 가장 객관적인 데이터와 서비스를 제공하며 성장하고 있습니다. </span><br><br>
                <span class="slide2">보험의 필요성을 알지만 믿음을 가지지 못하는 고객님을 위해 의심이나 불신 없이<br> 보험을 알아볼 수 있도록 <mark>상담매니저가 보험 가입의 권유 없이 무료로 서비스를 이용</mark>할 수 있습니다. </span><br><br>
                <span class="slide0"><mark>보클 처방전을 통해 전국 어디에서나 표준화된 상담 서비스를 제공할 것을 약속합</mark>니다.</span>
            </p>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <p class="txt"> 보험클리닉만의 <strong>'보클 처방전'</strong>으로 믿을 수 있는<br> 상담매니저가 보험을 무료로 진단해드립니다.<br><br> 전국 어디에서나 동일하게 만나는<br> <strong>'보클 처방전'</strong>을 지금 경험해보세요. </p>
                        <img class="book" :src="imgPath+'service_book.png'" alt="보클 처방전">
                        <img class="model" :src="imgPath+'service_model.png'" alt="모델">
                    </div>
                    <div class="swiper-slide">
                        <ul class="list_service">
                            <li class="item_service">
                                <img class="ico" :src="imgPath+'service_ico1.png'" alt="">
                                <p class="tit"><mark>보험진단 서비스</mark></p>
                                <p class="desc">자체 개발한 보험클리닉<br> App으로 보유<br> 보험 적정성 진단</p>
                            </li>
                            <li class="item_service">
                                <img class="ico" :src="imgPath+'service_ico2.png'" alt="">
                                <p class="tit"><mark>비교추천 서비스</mark></p>
                                <p class="desc">국내 주요보험사와 제휴하여<br> 다수 상품 中 <br> 고객이 직접선택 </p>
                            </li>
                            <li class="item_service">
                                <img class="ico" :src="imgPath+'service_ico3.png'" alt="">
                                <p class="tit"><mark>보험금 청구 대행 서비스</mark></p>
                                <p class="desc">가입한 보험사 상관없이<br> 무료 보험금 <br> 청구 대행 서비스</p>
                            </li>
                        </ul>
                    </div>
                    <div class="swiper-slide">
                        <ul class="list_service">
                            <li class="item_service">
                                <img class="ico" :src="imgPath+'service_ico4.png'" alt="">
                                <p class="tit"><mark>모든 상담이 무료</mark></p>
                                <p class="desc">보험클리닉 상담 매니저는<br> 서비스 제공 및 고객 가이드 안내를<br> 주 원칙으로 하며, 보험가입을<br> 권유하지 않습니다.</p>
                            </li>
                            <li class="item_service">
                                <img class="ico" :src="imgPath+'service_ico5.png'" alt="">
                                <p class="tit"><mark>원스톱 서비스</mark></p>
                                <p class="desc">가입된 보험에 대한 진단 분석부터<br> 보험금 청구 등 보험에 대한<br> 문의를 방문시 친절하고<br> 자세하게 안내해드립니다.</p>
                            </li>
                            <li class="item_service">
                                <img class="ico" :src="imgPath+'service_ico6.png'" alt="">
                                <p class="tit"><mark>보장분석 시스템</mark></p>
                                <p class="desc">보험증권 없어도 가입된<br> 보험이 기억나지 않아도,<br> 인증을 통해 가입된 모든 보험의<br> 진단이 가능합니다.</p>
                            </li>
                        </ul>
                    </div>                    
                </div>
                <div class="swiper-nav">
                    <!-- Add Pagination -->
                    <div class="swiper-pagination"></div>
                    <!-- Add Navigation -->
                    <div class="swiper-button swiper-button-next"><img class="ico" src="" data-images-path="/image/svg/next01.svg" alt="앞으로"></div>
                    <div class="swiper-button swiper-button-prev"><img class="ico" src="" data-images-path="/image/svg/prev01.svg" alt="뒤로"></div>
                </div>
            </div>
        </div>
        <div class="bg"></div>
    </section>
    <!-- //서비스 -->

    <!-- 수상 -->
    <section id="awards"></section>
    <!-- //수상 -->

    <!-- <section id="social" class="section dataTarget" data-scroll-cut-line="10">
        <div class="inner">
            <h2 class="tit_section">생생한 <strong>보험클리닉 이야기</strong></h2>
            <p class="tag">@bohum_clinic</p>
            <ul class="social-list">
                <li class="I ico_comm"><a href="//www.instagram.com/bohum_clinic" target="_blank" title="인스타그램 바로가기">인스타그램 바로가기</a></li>
                <li class="Y ico_comm"><a href="//www.youtube.com/channel/UCzcc4DNIpNngewyH0Lt1tpg" target="_blank" title="유튜브 바로가기">유튜브 바로가기</a></li>
                <li class="F ico_comm"><a href="//www.facebook.com/bohumclinic" target="_blank" title="페이스북 바로가기">페이스북 바로가기</a></li>
                <li class="B ico_comm"><a href="//blog.naver.com/bohum_clinic" target="_blank">블로그 바로가기</a></li>
            </ul>
            <div class="social-wrap-btn">
                <button type="button" class="prev">이전</button>
                <button type="button" class="next">다음</button>
            </div>
        </div>
        <div class="social-wrap">
            <div class="swiper-container">
                <ul class="swiper-wrapper">
                    <li class="swiper-slide" data-attr-type="type">
                        <a data-href="href" target="_blank">
                            <div class="top">
                                <p class="txt line_clamp2" data-text="title" data-clamp="2"></p>
                                <p class="hash-tag line_clamp1" data-text="description" data-clamp="1"></p>
                            </div>
                            <div class="thumb"></div>
                        </a>
                    </li>
                </ul>
            </div>
           
            <div class="social-btn">
                <button type="button" data-type="instagram" class="I on" title="인스타그램">인스타그램</button>
                <button type="button" data-type="youtube" class="Y on" title="유튜브">유튜브</button>
                <button type="button" data-type="facebook" class="F" title="페이스북">페이스북</button>
                <button type="button" data-class="B" class="B">블로그</button>
            </div>
        </div>
    </section> -->

    <!-- 2018  -->
    <section id="start" class="section dataTarget" data-scroll-cut-line="10">
        <div class="contents">
            <!-- <p class="year">SINCE <strong>2018</strong></p> -->
            <p class="tit">보험클리닉 런칭 이후 수많은<br>보험고민을 해결하고 있습니다.</p>
            <p class="desc">
                <span>우리는 고객의 니즈에 맞춰 <mark>능동적으로 보험을 찾을 수 있는 방법에 대해 고민</mark> 했습니다.<br></span>
                <span><mark>한 곳에서 보험에 대해 모든 궁금증을 해결할 수 있는 방법에 대해서도</mark> 고민했습니다.</span><br><br>
                <span>피플라이프는 고객의 상황에 맞춘 맞춤형 서비스를 제공하기 위해<br> <mark>고객이 방문하는 보험 서비스와 전문가가 고객에게 직접 찾아가는 서비스인 ‘보험클리닉’을 런칭</mark>했습니다.</span><br><br>
                <span>보험클리닉은 항상 고객님의 가까이에서<br> <mark>보험에 대한 모든 것을 쇼핑하고 보험전문가가 모든 궁금증을 해결하는</mark><br> <mark>최상의 O2O 보험서비스를 제공</mark>하기 위해 만들어졌습니다. </span>
            </p>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" style="background-image:url('/img/brand/bohum/start_bg1.jpg?v=221124')"></div>
                <div class="swiper-slide" style="background-image:url('/img/brand/bohum/start_bg2.jpg')"></div>
                <div class="swiper-slide" style="background-image:url('/img/brand/bohum/start_bg3.jpg?v=221129')"></div>
                <div class="swiper-slide" style="background-image:url('/img/brand/bohum/start_bg4.jpg?v=221129')"></div>
            </div>
            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                    <div class="swiper-button swiper-button-next"><img class="ico" src="" data-images-path="/image/svg/next01.svg" alt="앞으로"></div>
                    <div class="swiper-button swiper-button-prev"><img class="ico" src="" data-images-path="/image/svg/prev01.svg" alt="뒤로"></div>
            </div>
        </div>
    </section>
    <!-- //2018  -->

    <!-- 미래 -->
    <!-- <section id="future" class="section dataTarget" data-scroll-cut-line="10">
        <div class="inner">
            <h3 class="tit_section">대한민국 전국 어디서나, <strong>보험클리닉</strong></h3>
            <p class="desc_section">보험클리닉은 번화가 상권을 중심으로 지속 확장 중이며, 항상 고객님의 가까이에서 고민을 해결하고 있습니다.<br> 앞으로도 보다 나은 서비스 제공을 위해 노력하겠습니다.</p>
            <p class="tit_count"><mark>보험클리닉의 미래 목표</mark></p>
            <ul class="list_count">
                <li class="item_count"><p>2024년 보험상담매니저</p><p><em class="animateNumber">1,000</em> 명</p></li>
                <li class="item_count"><p>2024년 총 전국지점망</p><p><em class="animateNumber">500</em> 개</p></li>
                <li class="item_count"><p>한국소비자만족지수(주최:한경비즈니스) 2019-2020</p><p><em>1</em> 위</p></li>
            </ul>            
            <div class="map">
                <i class="seoul">서울</i>
                <i class="incheon">인천</i>
                <i class="gyeonggi">경기</i>
                <i class="gangwon">강원</i>
                <i class="chungnam">충남</i>
                <i class="chungbuk">충북</i>
                <i class="daejeon">대전</i>
                <i class="gyeongbuk">경북</i>
                <i class="daegu">대구</i>
                <i class="jeonbuk">전북</i>
                <i class="gwangju">광주</i>
                <i class="gyeongnam">경남</i>
                <i class="ulsan">울산</i>
                <i class="busan">부산</i>
                <i class="jeonnam">전남</i>
                <i class="jeju">제주</i>
                <img class="line" :src="imgPath+'future_line.svg'" alt="">
                <img class="bg" :src="imgPath+'future_map.png'" alt="전국지도">
            </div>
        </div>
    </section> -->
    <!-- //미래 -->

    <!-- 두가지 방법 -->
    <section id="gate" class="section dataTarget" data-scroll-cut-line="10">
        <div class="inner">
            <div class="txt_area shop">
                <p>가까운 보험샵(Shop)으로</p>
                <p>방문하는</p>
                <p>보험클리닉</p>
            </div>
            <div class="txt_area fa">
                <p>고객님이 계신 곳 <span>카페,</span><span>사무실,</span><span>집</span></p>
                <p>어디든지 찾아가는</p>
                <p>보험클리닉</p>
            </div>
            <a class="link_more shop" href="//www.bohumclinic.com/shop" target="_blank"><span>입장</span></a>
            <a class="link_more fa" href="//www.bohumclinic.com/visit" target="_blank"><span>입장</span></a>
        </div>
    </section>
    <!-- //두가지 방법 -->

    <!-- CI -->
    <section id="ci" class="section dataTarget" data-scroll-cut-line="10">
        <div class="inner">
            <h3 class="tit_section">보험클리닉 <strong>BI소개</strong></h3>
            <div class="download">
                <div class="box_logo">
                    <img src="" data-images-path="/image/logo/bohumclinic_extension01.svg" alt="로고">
                </div>
                <p class="txt">보험클리닉은 피드백과 소통이 이루어지는 이미지를 통해 누구나 보험클리닉을<br> 접함으로써 보험에 관한 모든 것을 묻고 상담하는 과정을 형상화 하였습니다.<br><br> 또한 방문, 상담, 체험하는 과정을 고래의 이미지를 통해 밝은 미래를 준비하고<br> 꿈꿀 수 있다는 메시지를 전달하고 있습니다.<br><br> 전체적으로 <strong>밝고 미래지향적인 이미지로 간결하고 생동감 있게 표현</strong>하였습니다.</p>
                <div class="btn_area">
                    <a class="link_download" href="" data-images-path="/data/logo/bohumclinic/jpg.zip" target="_blank" download><span>JPG 다운받기</span><img src="" data-images-path="/image/svg/download01.svg" alt="다운로드"></a>
                    <a class="link_download" href="" data-images-path="/data/logo/bohumclinic/ai.zip" target="_blank" download><span>AI 다운받기</span><img src="" data-images-path="/image/svg/download01.svg" alt="다운로드"></a>
                </div>
            </div>
            <div class="system">
                <p class="tit_system">Color System</p>
                <ul class="list_system">
                    <li class="item_system">
                        <p class="tit">BC_BLUE</p>
                        <p class="cmyk"><strong>CMYK</strong>C100 / M26 / Y0 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R0 / G142 / B211</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">BC_GREEN</p>
                        <p class="cmyk"><strong>CMYK</strong>C53 / M0 / Y100 / K0</p>
                        <p class="rgb"><strong>RGB</strong>R133 / G196 / B64</p>
                    </li>
                    <li class="item_system">
                        <p class="tit">BC_GRAY</p>
                        <p class="cmyk"><strong>CMYK</strong>C0 / M0 / Y0 / K65</p>
                        <p class="rgb"><strong>RGB</strong>R119 / G120 / B123</p>
                    </li>
                </ul>
            </div>
            <div class="official">
                <h3 class="tit_official">공식 이미지</h3>
                <ul class="list_official">
                    <li class="item_official">
                        <div class="tit">Basic</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_extension01.svg" alt="">
                    </li>
                    <li class="item_official">
                        <div class="tit">Character</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_character.svg" alt="">
                    </li>
                    <!-- <li class="item_official">
                        <div class="tit">Extension_01</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_extension01.svg" alt="">
                    </li> -->
                    <li class="item_official">
                        <div class="tit">Extension</div>
                        <img src="" data-images-path="/image/logo/bohumclinic_basic.svg" alt="">
                    </li>
                </ul>
                <p class="desc_official">시그니쳐는 브랜드명과 캐릭터, 사명의 조합으로 이루어진 형태를 기본으로 사용합니다. <br> 어플리케이션에 따라 위와 같이 확장형태 및 캐릭터의 단독사용이 가능합니다.<br><br> 또한 텍스트(문자)로의 보험클리닉 브랜드명 사용시, ‘보험클리닉 피플라이프’(전체 브랜드명) 혹은 <br> ‘보험클리닉’(브랜드명 단독사용)으로 사용가능하며, ‘피플라이프 보험클리닉’으로의 사용을 금합니다.</p>
            </div>
        </div>
    </section>
    <!-- //CI -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/brand/bohum/'
        }
    },
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/brand/bohum.scss';
</style>
