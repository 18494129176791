<!-- 2020년 수상내역 -->
<template>
    <section id="roadMap" class="section dataTarget">
        <div class="inner">
            <h3 class="tit_section">우리회사는 현재 어디쯤 지나가고 있을까요?</h3>
            <p class="desc_section">언제든 찾아올 수 있는 기업의 위기, 준비된 변화와 혁신이 반드시 필요합니다.</p>
            <div class="contents">
                <img src="/img/common/contents/roadMap/bg.png" alt="">
                <ul class="start">
                    <li>
                        <div class="box">
                            <span>법인설립</span>
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <span>법인전환</span>
                        </div>
                        <div class="desc">
                            일반사업양수도,<br>
                            신설법인 세감면 법인전환<br>
                            (현물출자, 사업양수도,<br>
                            중소기업 통합)
                        </div>
                    </li>
                </ul>
                <ul class="main">
                    <li class="line1">
                        <div class="box">
                            <span>법인제도 정비</span>
                            <p class="tooltip">
                                법인컨설팅의 시작,<br>
                                정관을 통한<br>
                                제도 정비
                            </p>
                        </div>
                        <div class="desc">
                            정관 등 제도 변경, 임원의 보수<br>
                            (급여, 상여, 퇴직금) 설계,<br>
                            노무제도 정비, 동업자 협정
                        </div>
                    </li>
                    <li class="line1">
                        <div class="box">
                            <span>주식이동</span>
                        </div>
                        <div class="desc">
                            차명주식 환원,<br>
                            명의신탁해지,<br>
                            배당을 통한 지분 분산
                        </div>
                    </li>
                    <li class="line1">
                        <div class="box">
                            <span>비상장주식가치평가</span>
                            <p class="tooltip">
                                법인세,<br>
                                상속/증여세,<br>
                                소득세 등 각종<br>
                                세금의 기준
                            </p>
                        </div>
                        <div class="desc">
                            주가적인 주식가치 평가 및<br>
                            주가 관리
                        </div>
                    </li>
                    <li class="line1">
                        <div class="box">
                            <span>자금조달</span>
                        </div>
                        <div class="desc">
                            기업신용도 관리,<br>
                            정책자금 활용,<br>
                            재무 및 투자 관리
                        </div>
                    </li>
                    <li class="line1">
                        <div class="box">
                            <span>법인세절세</span>
                        </div>
                        <div class="desc">
                            조특법상 감면, 세액 공제,<br>
                            가지급금과 접대비 등<br>
                            지출증빙 관리
                        </div>
                    </li>
                    <li class="line2">
                        <div class="box">
                            <span>기업인증</span>
                            <p class="tooltip">
                                여러 기관의<br>
                                공식적인 인정요인을<br>
                                입증하고 사실<br>
                                관계를 확인하는<br>
                                절차의 결과
                            </p>
                        </div>
                        <div class="desc">
                            벤처, 메인비즈, 이노비즈,<br>
                            ISO, 뿌리산업 등
                        </div>
                    </li>
                    <li class="line3">
                        <div class="box">
                            <span>R&D</span>
                        </div>
                        <div class="desc">
                            기업부설 연구소,<br>
                            연구전담부서 신설,<br>
                            직무발명보상제도 도입,<br>
                            특허 등
                        </div>
                    </li>
                    <li class="line3">
                        <div class="box">
                            <span>배당</span>
                        </div>
                        <div class="desc">
                            정기배당, 중간배당,<br>
                            현물배당 등
                        </div>
                    </li>
                    <li class="line3">
                        <div class="box">
                            <span>자녀임원 등재</span>
                        </div>
                        <div class="desc">
                            경영수업, 임원규정 정비,<br>
                            승계소요자금
                        </div>
                    </li>
                    <li class="line3">
                        <div class="box">
                            <span>세무조사 대응</span>
                        </div>
                        <div class="desc">
                            세무관리, 성실신고 자가진단,<br>
                            모의 세무조사
                        </div>
                    </li>
                    <li class="line3">
                        <div class="box">
                            <span>사내근로복지기금 설립</span>
                        </div>
                        <div class="desc">
                            근로자 복지증진, 가업승계,<br>
                            자기주식 연계
                        </div>
                    </li>
                    <li class="line4">
                        <div class="box">
                            <span>경정청구</span>
                            <p class="tooltip">
                                이미<br>
                                신고/결정/경정된<br>
                                새액을 정정하여<br>
                                경정 요청하는 납세<br>
                                의무자의 청구
                            </p>
                        </div>
                        <div class="desc">
                            고용지원금,<br>
                            고용관련 세액공제,<br>
                            통합투자 세액공제,<br>
                            연구인력 개발비 세액공제
                        </div>
                    </li>
                    <li class="line5">
                        <div class="box">
                            <span>자기주식취득</span>
                        </div>
                        <div class="desc">
                            자사주 취득, 보유, 처분 등
                        </div>
                    </li>
                    <li class="line5">
                        <div class="box">
                            <span>외감준비</span>
                        </div>
                        <div class="desc">
                            외감 실효성 검토,<br>
                            내부통제체계 구축,<br>
                            자금과 회계분리
                        </div>
                    </li>
                    <li class="line5">
                        <div class="box">
                            <span>상속플랜</span>
                            <p class="tooltip">
                                자산가를<br>
                                위한 성공적인<br>
                                자산이전인<br>
                                상속과 증여
                            </p>
                        </div>
                        <div class="desc">
                            가업상속공제,<br>
                            상속재산 배분,<br>
                            담세능력 확보
                        </div>
                    </li>
                    <li class="line5">
                        <div class="box">
                            <span>경영권 방어</span>
                        </div>
                        <div class="desc">
                            스톡옵션, 우리사주 등
                        </div>
                    </li>
                </ul>
                <ul class="end">
                    <li>
                        <div class="box">
                            <span>가업승계</span>
                            <p class="tooltip">
                                경영하는<br>
                                기업이 지속되도록<br>
                                소유권/경영권을<br>
                                물려주는 것
                            </p>
                        </div>
                        <div class="desc">
                            경영권 안정, 승계재원 확보
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <span>기업의 성장</span>
                        </div>
                        <div class="desc">
                            코스닥 상장(IPO)
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <span>기업의 매각</span>
                        </div>
                        <div class="desc">
                            인수, 합병, 분할, M&A 등
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <span>기업의 청산</span>
                        </div>
                        <div class="desc">
                            청산 법인세, 청산 소득세
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'roadMap',
}
</script>



<style lang="scss"> 
    #roadMap {
        padding: 80px 0px 340px;
        background-color: #f8f8f8;
        .tit_section {
            margin-bottom: 40px; font-size: 38px; font-weight: 500; text-align: center; line-height: 1.3em;
            .cate {
                &::after {
                    content: "개인영업";
                }
            }
        }
        .desc_section {
            margin-bottom: 45px; font-size: 16px; color: #666666; text-align: center;
        }
        .contents {
            position: relative; margin-top: 230px; font-size: 16px;
            ul {
                position: absolute;
                li {
                    margin-top: -1.4em;
                    .box {
                        float: left; position: relative;
                        span {display: block; position: relative; padding: 0 24px; line-height: calc(2.8em - 2px); height: 2.8em; color: #666; background-color: #fff; border-radius: 100vw; border: 1px solid #666;}
                        .tooltip {
                            position: absolute; bottom: 100%; left: 50%; display: flex; align-items: center; justify-content: center; margin: 80px 0 20px -80px; width: 160px; height: 160px; color: #cfeaf7; font-size: 0.88em; text-align: center; border: 12px solid #cfeaf7; background-color: #0f95d7; border-radius: 50%;
                            &::before {content: ""; position: absolute; top: calc(100% + 10px); transform: rotate(0deg); transform-origin: 50% -78px; left: 50%; margin-left: -8px; border-width: 16px 8px 0 8px; border-color: #cfeaf7 transparent transparent transparent;}
                        }
                        &:has(.tooltip) span {
                            color: #0f95d7; border-color: #0f95d7;
                            &::before {content: ""; position: absolute; top: 0; left: 50%; margin: -6px; width: 12px; height: 12px; background-color: #0f95d7; border: 2px solid #fff; border-radius: 50%;}
                        }
                    }
                    .desc {float: left; clear: both; margin-top: 0.5em; padding-left: 16px; line-height: 1.3; color: #666666; font-size: 0.88em; }
                }
                &.start {
                    top: calc(0% + 10px); left: -50px; transform: translateY(-50%);
                    li {
                        margin-top: 0; height: 44px;
                        .box {
                            span {border: 0; color: #fff; background-color: #0f95d7;}
                        }
                        .desc {position: absolute; top: 100%; left: 0; white-space: nowrap;}
                        & + li {
                            margin-top: 0.4em;
                            .box {
                                span {background-color: #666666;}
                            }
                        }
                    }
                }
                &.main {
                    top: 0px; left: 0px; width: 100%; height: 100%;
                    li {
                        position: absolute;
                        &.line1 {top: calc(0% + 10px);}
                        &.line2 {top: calc(25% + 0px);}
                        &.line3 {top: calc(50% - 4px);}
                        &.line4 {top: calc(75% - 10px);}
                        &.line5 {
                            top: calc(100% - 16px);
                            .tooltip {
                                top: 100%;
                                &::before {transform: rotate(180deg);}
                            }
                        }
                        &:nth-child(1) {left: 124px;} // 법인제도 정비
                        &:nth-child(2) {left: 330px;} // 주식이동
                        &:nth-child(3) {left: 490px;} // 비상장주식가치평가
                        &:nth-child(4) {left: 690px;} // 자금조달
                        &:nth-child(5) {left: 830px;} // 법인세절세
                        &:nth-child(6) {left: 1015px;} // 기업인증
                        &:nth-child(7) {left: 870px;} // R&D
                        &:nth-child(8) {left: 725px;} // 배당
                        &:nth-child(9) {left: 540px;} // 자녀임원 등재
                        &:nth-child(10) {left: 335px;} // 세무조사 대응
                        &:nth-child(11) {left: 120px;} // 사내근로복지기금 설립
                        &:nth-child(12) {left: -40px;} // 경정청구
                        &:nth-child(13) {left: 135px;} // 자기주식취득
                        &:nth-child(14) {left: 340px;} // 외감준비
                        &:nth-child(15) {left: 510px;} // 상속플랜
                        &:nth-child(16) {left: 675px;} // 경영권 방어
                    }
                }
                &.end {
                    bottom: 15px; left: 840px; transform: translateY(50%);
                    li {
                        position: relative; margin-top: 0; height: 44px;
                        .box {
                            position: relative;
                            span {
                                padding: 0; width: 120px; border: 0; color: #fff; text-align: center; background-color: #0f95d7;
                                &::before {content: none;}
                            }
                            .tooltip {left: 180px; margin-left: 0; margin-bottom: 4px;}
                            &::before {content: ""; position: absolute; top: 50%; left: 0; z-index: 1; margin: -6px; width: 12px; height: 12px; background-color: #0f95d7; border: 2px solid #fff; border-radius: 50%;}
                        }
                        .desc {position: absolute; top: 50%; left: 100%; transform: translateY(-50%); margin-top: 0; margin-left: 0.8em; padding-left: 0; color: #0f95d7; white-space: nowrap;}
                        & + li {
                            margin-top: 0.4em;
                            .box {
                                span {background-color: #666666;}
                                &::before {background-color: #666666;}
                            }
                            .desc {color: #666666;}
                        }
                    }
                }
            }
        }
    }
</style>