<template>
    <main id="container">
        <section id="complete" class="corp section">
            <h3 class="title">상담신청 완료</h3>
            <p class="info-step"><img src="/img/brand/info_step.gif" alt="스탭이미지"></p>
            <p class="txt">
                상담신청이 완료되었습니다.<br>
                기재해주신 번호로 <strong>1~2일 이내</strong>에 전화 드리겠습니다.<br><br>
                감사합니다.
            </p>
            <div class="btn-area">
                <a href="javascript:history.back();" class="btn">이전화면으로 이동</a>
            </div>
        </section>
    </main>
</template>
<style scoped lang="scss">
@import '@/assets/scss/common/complete.scss';
</style>
<script>
export default {
    
}
</script>