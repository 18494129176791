<!-- 전문가 네트워크 -->
<template>
	<section id="networkTab" class="section">
		<div class="inner">
		<h3 class="tit_section">전속 자문을 통한 One-Stop 서비스</h3>
		<p class="desc_section">법인 경영의 리스크를 최소화하고 기업과 주주들의 성장에 도움이 될 수 있도록 집중 관리해 드립니다. </p>
		<div class="list_tab hidden">
			<button class="btn_tab" type="button">전문가 네트워크</button>
			<button class="btn_tab" type="button">전속 네트워크</button>
			<button class="btn_tab" type="button">제휴 네트워크</button>
		</div>
		<ul class="list_illust">
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust1.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">세무법인<br>세종TSI</p>
				</div>
			</li>
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust2.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">가이우스<br>법률사무소</p>
				</div>
			</li>
			<!-- <li class="item_illust">
				<img src="/img/common/contents/networkTab/illust3.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">법무사<br>김주현 사무소</p>
				</div>
			</li> -->
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust4.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">노무법인<br>익선</p>
				</div>
			</li>
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust5.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">노무법인<br>서현</p>
				</div>
			</li>
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust6.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">한국<br> M&A거래소</p>
				</div>
			</li>
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust7.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">IPO·투자유치<br>·M&A자문</p>
				</div>
			</li>
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust8.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">부동산<br>중개</p>
				</div>
			</li>
			<!-- <li class="item_illust">
				<img src="/img/common/contents/networkTab/illust9.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">감정평가법인<br>대일감정원</p>
				</div>
			</li> -->
			<!-- <li class="item_illust">
				<img src="/img/common/contents/networkTab/illust10.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">에이스<br>손해사정평가</p>
				</div>
			</li> -->
			<!-- <li class="item_illust">
				<img src="/img/common/contents/networkTab/illust11.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">청일국제특허<br>법률사무소</p>
				</div>
			</li> -->
			<li class="item_illust">
				<img src="/img/common/contents/networkTab/illust12.png" alt="네트워크일러스트">
				<div class="box_txt">
					<p class="tit">DPBS</p>
				</div>
			</li>
		</ul>
		</div>
	</section>
</template>



<script>
export default {
  name: "networkTab",
};
</script>



<style lang="scss">
#networkTab {
	padding-bottom: 85px;
	.tit_section {
		margin-bottom: 40px;
		font-size: 38px;
		font-weight: 500;
		text-align: center;
		line-height: 1.3em;
		.cate {
		&::after {
			content: "개인영업";
		}
		}
	}
	.desc_section {
		margin-bottom: 45px;
		font-size: 16px;
		color: #666666;
		text-align: center;
	}
	.list_tab {
		display: flex;
		flex-direction: row;
		background-color: #eeeeee;
		.btn_tab {
		position: relative;
		display: inline-flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		height: 70px;
		font-size: 24px;
		font-weight: 500;
		transition: all 0.4s ease-in-out;
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: -5px;
			width: 0px;
			height: 0px;
			border-top: 10px solid transparent;
			border-right: 10px solid #0f95d7;
			transform: rotate(45deg);
			opacity: 0;
			transition: all 0.4s ease-in-out;
		}
		}
		@for $i from 1 through 3 {
		&[data-tab-index="#{$i}"] {
			.btn_tab {
			&:nth-child(#{$i}) {
				color: #fff;
				background-color: #0f95d7;
				&::after {
				opacity: 1;
				}
			}
			}
		}
		}
	}
.list_illust {
	$i: 0;
	$txtTop: -40px;
	$txtBottom: 405px;
	$txtHidden: -9999px;
	$position: 
	//                  left1             left2             left3            opacity1     opacity2     opacity3     top1              top2              top3
     calc(1300px/13*1 -  60px)   calc(1180px/6*1)            100px            1            1            0            $txtBottom        $txtBottom        $txtHidden, //세종TSI
     calc(1300px/13*2 +   0px)   calc(1180px/6*2)            200px            1            1            0            $txtTop           $txtBottom        $txtHidden, //가이우스법률사무소
     calc(1300px/13*3.5 + 0px)   calc(1180px/6*3)            300px            1            1            0            $txtBottom        $txtBottom        $txtHidden, //노무법인서현
     calc(1300px/13*5 +   0px)             400px   calc(1180px/8*1)           1            0            1            $txtTop           $txtHidden        $txtBottom, //노무법인익선
     calc(1300px/13*6.5 + 0px)             500px   calc(1180px/8*2)           1            0            1            $txtBottom        $txtHidden        $txtBottom, //한국M&A거래소
     calc(1300px/13*8 +   0px)             600px   calc(1180px/8*3)           1            0            1            $txtTop           $txtHidden        $txtBottom, //IPO·투자유치·M&A자문
     calc(1300px/13*9.5 + 0px)    calc(1180px/6*4)           700px            1            1            0            $txtBottom        $txtBottom        $txtHidden, //부동산중개
     calc(1300px/13*11 +  0px)            1100px   calc(1180px/8*6)           1            0            1            $txtTop           $txtHidden        $txtBottom, //DPBS
     calc(1300px/13*8 +  15px)   calc(1180px/6*5)            800px            1            1            0            $txtTop           $txtBottom        $txtHidden, //대일감정평가법인
     calc(1300px/13*9 +  35px)             900px   calc(1180px/8*4)           1            0            1            $txtBottom        $txtHidden        $txtBottom, //에이스손해사정평가
     calc(1300px/13*10 + 50px)            1000px   calc(1180px/8*5)           1            0            1            $txtTop           $txtHidden        $txtBottom; //청일국제특허법률사무소
	position: relative;
	height: 520px;
	background-image: url(/img/common/contents/networkTab/illust_bg_transparents.png);
	opacity: 0;
	transition: background-image 0.6s ease-in-out;
	.item_illust {
		position: absolute;
		top: 50px;
		transition: all 0.6s ease-in-out, opacity 0.3s linear;
		transform: scale(0.6);
		img {
			max-width: none;
		}
		.box_txt {
			position: absolute;
			left: 50%;
			width: 200px;
			height: 70px;
			font-size: 16px;
			color: #111;
			text-align: center;
			transform: translateX(-50%);
			.tit {
				margin-bottom: 14px;
				line-height: 1em;
			}
			.desc {
				font-size: 13px;
				color: #666;
			}
		}
	}
	@each $left1, $left2, $left3, $opacity1, $opacity2, $opacity3, $top1, $top2, $top3 in $position {
		$i: $i + 1;
		&[data-tab-index="1"] {
			opacity: 1;
			.item_illust {
				&:nth-child(#{$i}) {
					transform: translateX(-50%) scale(0.9);
					left: $left1;
					opacity: $opacity1;
					.box_txt {
						top: $top1;
						.tit {
							font-size: 1.2em;
							transition: 0.5s
						}
					}
				}
			}
		}
		&[data-tab-index="2"] {
			opacity: 1;
			.item_illust {
			&:nth-child(#{$i}) {
				transform: translateX(-50%) scale(1);
				left: $left2;
				opacity: $opacity2;
				.box_txt {
				top: $top2;
				}
			}
			}
		}
		&[data-tab-index="3"] {
			opacity: 1;
			.item_illust {
			&:nth-child(#{$i}) {
				transform: translateX(-50%) scale(1);
				left: $left3;
				opacity: $opacity3;
				.box_txt {
				top: $top3;
				}
			}
			}
		}
	}
	}
	&.appear {
		.item_illust {
			@for $i from 1 through 15 {
				&:nth-child(#{$i}) {
				transform: scale(0.9);
				transition-delay: #{$i * 0.05}s;
				}
			}
		}
	}
}
</style>