import { render, staticRenderFns } from "./CorpComplete.vue?vue&type=template&id=06d460fe&scoped=true&"
import script from "./CorpComplete.vue?vue&type=script&lang=js&"
export * from "./CorpComplete.vue?vue&type=script&lang=js&"
import style0 from "./CorpComplete.vue?vue&type=style&index=0&id=06d460fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d460fe",
  null
  
)

export default component.exports