<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프와 함께라<br> 당신의 성공신화는 잠들지 않습니다</h3>
            <div class="contents">
                <p class="tit_contents"><strong>PEOPLELIFE</strong> RECRUIT</p>
                <ul class="list_recruit">
                    <li class="item_recruit" onClick="location.href='/recruit/RecruitCorp'"> <!-- .close -->
                        <p class="state"></p>
                        <p class="tit">법인컨설팅 채용</p>
                        <p class="desc lineClamp2">명실상부 법인컨설팅 명가에서<br> 다음 성공의 주인공이 되세요!</p>
                        <a class="link_more" href="/recruit/RecruitCorp"></a>
                    </li>
                    <li class="item_recruit" onClick="location.href='/recruit/RecruitFa'"> <!-- .close -->
                        <p class="state"></p>
                        <p class="tit">개인영업(FA)<br> 채용</p>
                        <p class="desc lineClamp2">FA가 가장 행복한 피플라이프에서<br> 한 단계 더 도약하세요!</p>
                        <a class="link_more" href="/recruit/RecruitFa"></a>
                    </li>
                    <li class="item_recruit" onClick="location.href='/recruit/RecruitBohum'"> <!-- .close -->
                        <p class="state"></p>
                        <p class="tit">보험클리닉<br>  채용</p>
                        <p class="desc lineClamp2">보험클리닉 전문매니저가 되기 위한<br> 첫걸음! 롤모델의 주인공이 되세요!</p>
                        <a class="link_more" href="/recruit/RecruitBohum"></a>
                    </li>
                    <!-- <li class="item_recruit close" onClick="location.href='/recruit/RecruitEfa'">
                        <p class="state"></p>
                        <p class="tit">정규직 보험클리닉<br> (EFA) 채용</p>
                        <p class="desc lineClamp2">안정성과 전문성을 갖춘 EFA에<br> 도전하세요!</p>
                        <a class="link_more" href="/recruit/RecruitEfa"></a>
                    </li> -->
                </ul>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 채용후기 -->
    <section id="commnet" class="section">
        <div class="inner">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <p>피플라이프에 오게 된 후 이전에는 몰랐던 것들을 너무 많이 알게 됐어요. 이제야 고객에게 부족한 부분들을 속속들이 알차게 채워드릴 수 있게 되었고, 덕분에 하루가 24시간이 아니라 48시간이었으면 좋겠다고 느낄 정도로 바쁜 시간을 보내고 있습니다.</p>
                        <span class="name">피플라이프 FA 이O남 팀장</span>
                    </div>
                    <div class="swiper-slide">
                        <p>보험에 입문했을 당시에만 해도 막연했던 것들이 피플라이프에서 이뤄지고 있어요. 직업적 안정과, 안정된 소득이 필요했던 저에게 정규직 보험상담매니저는 아쉬움이 가득했던 부분들을 충족시켜 줄 수 있는 선택이었어요. 저의 역량과 만족도를 최대한으로 끌어주는 회사에서 고객에게 더 좋은 서비스를 제공할 수 있어서 너무 기쁩니다.</p>
                        <span class="name">피플라이프 EFA 박O아 매니저 </span>
                    </div>
                    <div class="swiper-slide">
                        <p>쾌적하고 안정적인 환경, 고객이 직접 찾아와 서비스를 요청한다는 점이 매력적인 점이라고 생각했어요. 또 보험클리닉은 영업이 주 목적이 아니기 때문에, 고객의 이야기를 경청하고 고객의 불편함을 해결하기 위해 노력해요. 만족감을 느끼는 고객을 보면 자부심을 느끼곤 합니다. 이게 제가 진정으로 제공하고 싶던 서비스라는 생각이 듭니다.</p>
                        <span class="name">보험클리닉 김O민 매니저</span>
                    </div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <div class="swiper-button-prev swiper-button"><img src="" data-images-path="/image/svg/arr_top01.svg" alt="위로"></div>
                <div class="swiper-button-next swiper-button"><img src="" data-images-path="/image/svg/arr_bottom01.svg" alt="아래로"></div>
            </div>
        </div>
    </section>
    <!-- //채용후기 -->

    <!-- 영업지원 -->
    <section id="support" class="section">
        <div class="inner clearfix">
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">교육지원</p>
                            <p class="desc">법인을 대상으로 한 컨설팅 영업을 조직화하여 컨설턴트의 사업적 비전과<br>품격을 한 단계 업그레이드한 원동력은 바로 교육의 힘입니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">광고·홍보 지원</p>
                            <p class="desc">피플라이프는 보다 전문적이고 세련된 기업브랜드를 구축하고자 유명 배우를 광고<br>모델로 대대적 광고를 시행하여 기업 신뢰도 향상에 힘을 기울이고 있습니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">상품제휴 </p>
                            <p class="desc">국내 주요보험사와 상품판매 업무제휴를 맺어<br> 상품 비교를 통해 고객 중심의 맞춤형 상품을 제안합니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">전문가 네트워크 </p>
                            <p class="desc">피플라이프는 중소기업 경영지원컨설팅 분야에서 뛰어난 솔루션을 보유한<br> 전문가 그룹과 제휴관계를 맺고 있습니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">고객세미나 지원 </p>
                            <p class="desc">아마추어 골프대회, 이슈별 고객초청 세미나를 통해 기존 고객에 대한 서비스<br>측면 외에도 신규고객 창출을 위한 뛰어난 인프라를 구축하고 있습니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">전산시스템</p>
                            <p class="desc">자체 개발한 ERP 시스템 PINES를 통해 PC, Mobile에서 통합 고객관리 시스템이<br>지원되며 지능형보장분석시스템으로 경쟁력을 지원합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="headline"><strong>Business</strong> support</p>
                            <p class="tit">DB제공 시스템</p>
                            <p class="desc">피플라이프만의 다양한 마케팅을 통해 고객들이 자발적으로 찾아오는<br>양질의 시장을 제공하므로써 컨설턴트의 활동을 지원합니다</p>
                        </div>
                    </div>
                </div>

                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <div class="swiper-button-prev swiper-button"><img src="" data-images-path="/image/svg/arr_top01.svg" alt="위로"></div>
                <div class="swiper-button-next swiper-button"><img src="" data-images-path="/image/svg/arr_bottom01.svg" alt="아래로"></div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb1.jpg'" alt="사진"></div>
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb2.jpg'" alt="사진"></div>
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb3.jpg'" alt="사진"></div>
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb4.jpg'" alt="사진"></div>
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb5.jpg'" alt="사진"></div>
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb6.jpg'" alt="사진"></div>
                    <div class="swiper-slide"><img :src="imgPath+'support_thumb7.jpg'" alt="사진"></div>
                </div>
            </div>
        </div>
    </section>
    <!-- //엽업지원 -->

    <!-- 채용절차 -->
    <!-- <section id="process" class="section">
        <div class="inner">
            <h3 class="tit_section">채용절차</h3>
            <ol class="list_process">
                <li class="item_process">
                    <i class="step"></i>
                    <p class="tit">서류전형</p>
                </li>
                <li class="item_process">
                    <i class="step"></i>
                    <p class="tit">1차 실무진면접</p>
                </li>
                <li class="item_process">
                    <i class="step"></i>
                    <p class="tit">2차 임원면접</p>
                </li>
                <li class="item_process">
                    <i class="step"></i>
                    <p class="tit">최종합격</p>
                </li>
            </ol>
            <p class="cmt">* 채용 전형에 따라 절차가 달라질 수 있습니다. 자세한 내용은 채용공고를 참고 바랍니다.</p>
        </div>
    </section> -->
    <!-- //채용절차 -->

    <!-- 복리후생 -->
    <!-- <section id="welfare" class="section">
        <div class="inner">
            <h3 class="tit_section">복리후생</h3>
            <p class="desc_section">
                피플라이프는 임직원의 복지향상을 위해 다양한 복리후생 제도를 운영하고 있습니다.
                <span style="display: block; margin-top: 10px; font-size: 0.9em; opacity: 0.9;">
                    ※ 채용직군에 따라 일부 상이할 수 있습니다.
                </span>
            </p>
            <ul class="list_welfare">
                <li class="item_welfare" style="color:#ffffff;background-color:#4371bd;"><strong>4대 보험 및 퇴직금</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#ffffff;"><strong>연차</strong>근로기준법에 의한<br>근속기간별 추가 휴가 제공</li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#f5f5f5;"><strong>자유로운<br>연차 사용 권장</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#ffffff;"><strong>경조사 휴가/경조금<br>화환 지급</strong></li>                
                <li class="item_welfare" style="color:#4c4c4c;background-color:#e6e6e6;"><strong>장학 지원금</strong>초,중,고,대학교</li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#f5f5f5;"><strong>출산휴가</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#ffffff;"><strong>육아휴직</strong></li>
                <li class="item_welfare" style="color:#ffffff;background-color:#4371bd;"><strong>장기근속자 우대</strong>휴가 및 보너스</li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#e6e6e6;"><strong>매년 STAFF<br>해외연수</strong></li>
                <li class="item_welfare" style="color:#ffffff;background-color:#4371bd;"><strong>PC 오프제</strong>근무시간 이후 자동PC종료</li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#ffffff;"><strong>분기별 워크샵</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#f5f5f5;"><strong>야근 시 연장근무 수당 지급<br> 또는 대체 휴가</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#ffffff;"><strong>휴일근로 시 휴일근무수당<br>또는 휴가 지급</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#f5f5f5;"><strong>점심휴게시간<br>12:00 ~ 13:30분 제공</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#e6e6e6;"><strong>임산부의 경우<br>태아검진 시간 허용</strong></li>
                <li class="item_welfare" style="color:#4c4c4c;background-color:#f5f5f5;"><strong>육아기 근로시간 단축 및<br> 가족돌봄휴직 및<br> 근로시간 단축</strong></li>
                <li class="item_welfare" style="color:#ffffff;background-color:#4371bd;"><strong>특별휴가 제공</strong>격려휴가, 포상휴가, 특별휴가 등</li>
            </ul>
        </div>
    </section> -->
    <!-- //복리후생 -->

    <!-- 인재지원 -->
    <section id="people" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프 인재지원</h3>
            <p class="desc_section">경력개발, 교육체계, 공정한 평가를 통해 개인의 성장을 지원합니다.</p>
            <ul class="list_people">
                <li class="item_people">
                    <p class="tit">공평한 기회</p>
                    <p class="desc">학력, 성별 등 조건에 상관없이 개인의 능력과<br> 자질을 존중하는 블라인드 테스트를 통해 공정한<br> 기회를 제공합니다.</p>
                </li>
                <li class="item_people">
                    <p class="tit">체계적인 교육</p>
                    <p class="desc">근무중인 상담매니저에게 정기적인 교육 및<br> 경력개발을 적극 지원하며 업계의 핵심인재로<br> 육성합니다.</p>
                </li>
                <li class="item_people">
                    <p class="tit">성과에 따른 보상제도</p>
                    <p class="desc">성과에 따른 인센티브가 제공되며, 조직 기여도<br> 등 기타 평가를 통해 진급 등 체계적인 보상<br> 제도를 운영하고 있습니다. </p>
                </li>
            </ul>
        </div>
    </section>
    <!-- //인재지원 -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="txt1">새로운 Role Model이 되고 싶다면 ?</p>
            <h3 class="txt2">지금 바로. 잡으세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <em class="txt3">※ 본 화면은 보험설계사를 모집하는 것으로 정규 또는 비정규 직원채용과 무관합니다.</em>
        </div>
    </section>
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/recruit/info/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitinfo.scss';
</style>